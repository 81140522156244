import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'src/app/core/globals.service';
import { PMSOpsService } from 'src/app/core/pms-ops.service';
import { UserService } from 'src/app/core/user.service';



export interface PmsOpsData {
  Date : String;
  Electricity_Units : String;
  Daily_Briefing_photo : String;
  Daily_Data: String;
  Weekly_Data: String;
  Monthly_Data: String;
}
@Component({
  selector: 'app-pms-ops-data',
  templateUrl: './pms-ops-data.component.html',
  styleUrls: ['./pms-ops-data.component.scss']
})

export class PmsOpsDataComponent implements OnInit {
  ELEMENT_DATA : PmsOpsData[];
  hotelRoute: any;
  hotelId: any;
  FromDate: any;
  ToDate: any;
  currentUser: any;
  globals: Globals;
  showEdit = false;
  features: any = {};
  hotelData: any = {};
  pmsLink = 'pms.aiosell.com';

  constructor( private route: ActivatedRoute, private datePipe: DatePipe, private pmsOpsService: PMSOpsService, private router: Router, globals: Globals, private userService : UserService) {
    this.globals = globals;
   }

  displayedColumns: string[] = ['Date', 'Electricity_Units', 'Daily_Briefing_photo', 'Daily_Data', 'Weekly_Data', 'Monthly_Data', 'take_action'];
  
  dataSource = new MatTableDataSource<PmsOpsData>();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.hotelRoute = this.route.params.subscribe(params => {
      this.hotelId = params.hotelId;
    });
    this.route.queryParams.subscribe(queryParams => {
      this.currentUser = queryParams.user;
      const body = {username: this.currentUser};
      this.userService.getUserData(body).subscribe(data => {
        this.features = data;
      });
    });
    
    this.lastdayFilter();
    this.getData();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getHotel();
    
  }

  lastdayFilter(){
    var date = new Date();
    const datecopy = new Date(Number(date));
    datecopy.setDate(1);
    this.FromDate = this.datePipe.transform(datecopy, 'yyyy-MM-dd');
    this.ToDate=this.datePipe.transform(date, 'yyyy-MM-dd');
  }
  getData(){
    let resp = this.pmsOpsService.fetchPmsOpsData(this.hotelId, this.FromDate, this.ToDate)
    resp.subscribe(report => {this.dataSource.data = report as PmsOpsData[]
    });
  }

  editROw(value){
    this.router.navigate(['/create-pms-operations-entry/'+this.hotelId], {state: {showbackButton: true, data: value}, queryParams: {user: this.currentUser }});
  }

  getHotel() {
    this.userService.getHotel(this.hotelId).subscribe(res => {
      this.hotelData = res;
      if (this.hotelData?.globals?.white_label?.pmsLink) {
        this.pmsLink = this.hotelData.globals.white_label.pmsLink;
      }
    });
  }

}
