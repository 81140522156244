import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReservationService } from 'src/app/core/reservation.service';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/core/user.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IRoomInfo, IRateplan, ILeadsInfo } from 'src/app/models/rooms';

@Component({
  selector: 'app-create-group-booking',
  templateUrl: './create-group-booking.component.html',
  styleUrls: ['./create-group-booking.component.scss'],
})
export class CreateGroupBookingComponent implements OnInit {
  @Input() hotelId: string;
  @Input() mode: 'new' | 'modify';
  @Input() user: string;
  @Input() payload: any;
  @Input() leadsProps: { status: string; valid: boolean; createdBy: string; expirationDate: string; leadId: string};

  @Output() showBookings = new EventEmitter<boolean>();
  @Output() reservationPayload = new EventEmitter();

  config: any;
  checkin: any;
  checkout: any;
  formData: any;
  numRooms: any;
  total = { amount: 0, tax: 0 };
  groupName: string;
  guestInfo = {
    lastName: '',
    address: { city: '', state: '', zipCode: '', line1: '', country: '' },
    email: '',
    firstName: '',
    phone: '',
  };
  updated = false;
  rateChangeTimer: any;

  bookingsForm: FormGroup;
  roomTypeInfo: any = {};
  reservations: any[] = [];
  mainReservation: any = {};
  mainTotal: { amount: number; tax: number } = { amount: 0, tax: 0 };
  paymentMode: string;
  billTo: string;
  cachedTax: any = {};
  taxInclusive: boolean;
  bookedBy: '';
  specialRequests: '';
  firstCalc = true;
  newFile:any;
  prePaidAmount = 0;
  billtoForPrePaid = null;
  prePaymentMode = 'Cash';
  commentsPrepaid = null;
  attachment: any;
  paxSelected = 1;
  city = '';
  country = '';

  constructor(
    public reser: ReservationService,
    public datePipe: DatePipe,
    public userService: UserService,
    public dialog: MatDialog,
    public fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initBookingsForm();
    this.subscribeToChange();
    this.getCurrentDate();
  }

  initBookingsForm() {
    this.bookingsForm = this.fb.group({
      rooms: this.fb.array([]),
    });
  }

  subscribeToChange() {
    this.roomFormArray.valueChanges.subscribe(() => {
      this.roomInfoChange();
    });
  }

  get roomFormArray() {
    return this.bookingsForm.controls.rooms as FormArray;
  }

  // Push room row to form array
  pushRoomItem(roomId: string, index: number) {
    for (const type of this.formData.roomtypesInfo) {
      if (type.roomId === roomId) {
        const roomBody: IRoomInfo = {
          roomId: type.roomId,
          mealplan: type.mealplans[0],
          name: type.displayName,
          num: 0,
          pax: 1,
          childpax: 0,
          rate: type.rateplans[0].perDayPretaxRate,
          taxInclusive: this.taxInclusive,
          rateplan: type.rateplans[0],
        };
        this.roomFormArray.insert(index + 1, this.getRoomFormGroup(roomBody));
        this.roomTypeInfo[roomId].count++;
        break;
      }
    }
  }

  deleteRoomItem(roomId: string, index: number) {
    this.roomFormArray.removeAt(index);
    this.roomTypeInfo[roomId].count--;
  }

  getRoomFormGroup(data: IRoomInfo): FormGroup {
    return this.fb.group({
      roomId: [data.roomId, [Validators.required]],
      mealplan: [data.mealplan, [Validators.required]],
      name: [data.name, [Validators.required]],
      num: [data.num, [Validators.required]],
      pax: [data.pax, [Validators.required]],
      childpax: [data.childpax],
      rate: [data.rate, [Validators.required]],
      taxInclusive: [data.taxInclusive, [Validators.required]],
      rateplan: [data.rateplan],
    });
  }

  updatePayload() {
    if (!this.updated && this.payload) {
      this.groupName = this.payload.groupName;
      this.checkin = this.payload.checkin;
      this.checkout = this.payload.checkout;
      this.guestInfo = this.payload.guest;
      this.paymentMode = this.payload.pms_payment?.payment_mode;
      this.billTo = this.payload.pms_payment?.bill_to;
      this.specialRequests = this.payload.specialRequests;
      this.bookedBy = this.payload.bookedBy;
      this.city = this.payload.city;
      this.country = this.payload.country;
      if (this.payload.pms_payment?.pre_payment_info){
        this.prePaidAmount = this.payload.pms_payment?.pre_payment_info?.amount;
        this.prePaymentMode = this.payload.pms_payment?.pre_payment_info?.payment_mode;
        this.billtoForPrePaid = this.payload.pms_payment?.pre_payment_info?.bill_to;
        this.commentsPrepaid = this.payload.pms_payment?.pre_payment_info?.comments;
        this.attachment = this.payload.pms_payment?.pre_payment_info?.attachment;
      }
      
    }

    while (this.payload.rooms?.length) {
      const rooms = this.payload.rooms;
      const cItem = rooms.pop();
      let numOfRoom = 1;

      for (let i = rooms.length - 1; i >= 0; i--) {
        if (
          rooms[i].id === cItem.id &&
          rooms[i].occupancy.adults === cItem.occupancy.adults &&
          rooms[i].prices[0].sellRate === cItem.prices[0].sellRate &&
          rooms[i].rateplan.id === cItem.rateplan.id
        ) {
          numOfRoom++;
          rooms.splice(i, 1);
        }
      }

      const roomBody: IRoomInfo = {
        roomId: cItem.id,
        mealplan: cItem.rateplan.name,
        name: cItem.name,
        num: numOfRoom,
        pax: cItem.occupancy.adults,
        childpax: cItem.occupancy.children,
        rate: cItem.prices[0].sellRate,
        taxInclusive: this.taxInclusive,
        rateplan: this.getRatePlan(
          cItem.id,
          cItem.rateplan.name,
          cItem.occupancy.adults,
          cItem.prices[0].sellRate,
          cItem.prices[0].tax
        ),
      };

      this.roomFormArray.push(this.getRoomFormGroup(roomBody));
    }

    this.roomInfoChange();
    this.addMissingRow();
    this.firstCalc = false;
  }

  // on update add rows of type not available
  addMissingRow() {
    const mapNumRoom = {};
    this.roomFormArray.controls.forEach((e) => {
      mapNumRoom[e.value.roomId] = mapNumRoom[e.value.roomId]
        ? mapNumRoom[e.value.roomId] + 1
        : 1;
    });

    for (const type in this.roomTypeInfo) {
      if (type in mapNumRoom) {
        this.roomTypeInfo[type].count = mapNumRoom[type];
      } else {
        for (const item of this.formData.roomtypesInfo) {
          if (item.roomId === type) {
            this.insertRooms(item);
          }
        }
      }
    }
  }

  getConfig() {
    this.userService.getProductConfig(this.hotelId).subscribe((data) => {
      this.config = data?.conf;
      this.getBookingInfo();
    });
  }

  getCurrentDate() {
    this.userService.getCurrentDate(this.hotelId).subscribe(res => {
      this.initDates(res?.currentDate);
      this.getConfig();
    })
  }

  initDates(newdate: string) {
    const offset = new Date().getTimezoneOffset() * 60000;
    if (this.mode === 'modify'){
      this.checkin = this.payload.checkin;
      this.checkout = this.payload.checkout;
    }else{
      this.checkin = newdate;
      this.checkout = new Date(new Date(this.checkin).getTime() + offset);
      this.checkout.setDate(this.checkout.getDate() + 1);
      this.checkout = this.datePipe.transform(new Date(this.checkout), 'y-MM-dd');
    }
  }

  onDateChange() {
    if (this.checkin >= this.checkout) {
      this.initDates(this.checkin);
    }
    this.getBookingInfo();
  }

  getBookingInfo() {
    var bookingId = null;
    if (this.payload  && this.payload.bookingId){
      bookingId = this.payload.bookingId
    }
    this.reser
      .getRequiredInfo(this.hotelId, this.checkin, this.checkout, this.mode, bookingId)
      .subscribe((data) => {
        this.formData = data;
        this.taxInclusive = this.formData.taxInclusive;

        this.paymentMode = this.formData?.paymentModes?.length
          ? this.formData.paymentModes[0]
          : 'Cash';
        this.mapRoomTypesInfo();

        if (this.mode === 'new' && this.roomFormArray.length === 0) {
          // Insert 1 type of each room to form
          this.formData.roomtypesInfo.forEach((type) => {
            this.insertRooms(type);
          });
        }

        if (this.mode === 'modify') {
          this.updatePayload();
          this.updated = true;
        }

        this.roomInfoChange();
        this.taxInclusiveToggle(this.taxInclusive);
      });
  }

  mapRoomTypesInfo() {
    this.formData?.roomtypesInfo?.forEach((e) => {
      e.count = 1;
      this.roomTypeInfo[e.roomId] = e;
    });
  }

  insertRooms(type) {
    const roomBody: IRoomInfo = {
      roomId: type.roomId,
      mealplan: type.mealplans[0],
      name: type.displayName,
      num: 0,
      pax: 1,
      childpax: 0,
      rate: type.rateplans[0].perDayPretaxRate,
      taxInclusive: this.taxInclusive,
      rateplan: type.rateplans[0],
    };

    this.roomFormArray.push(this.getRoomFormGroup(roomBody));
  }

  roomInfoChange() {
    this.total = this.calculateTotal();
  }

  addRoom(row: IRoomInfo) {
    const seperateRooms = [];
    let numRooms = row.num;
    while (numRooms--) {
      const body = {
        guest: this.guestInfo,
        rateplan: { id: row.rateplan.rateplanId, name: row.rateplan.mealplan },
        occupancy: { adults: parseInt(row.pax?.toString(), 10), children: parseInt(row.childpax?.toString(), 10) },
        currency: this.formData?.currencyCode,
        prices: [],
        id: row.roomId,
        name: row.name,
      };

      const offset = new Date().getTimezoneOffset() * 60000;
      const checkinDate = new Date(new Date(this.checkin).getTime() + offset);
      const checkoutDate = new Date(new Date(this.checkout).getTime() + offset);

      for (
        let i = checkinDate;
        i < checkoutDate;
        i = new Date(i.setDate(i.getDate() + 1))
      ) {
        const date = this.datePipe.transform(i, 'y-MM-dd');
        const sellRate = this.getSellRate(row.rateplan.perDayPretaxRate);
        const tax = row.rateplan.perDayTax;
        body.prices.push({ date, sellRate, tax, extra_charge: 0 });
        if (!row.taxInclusive) {
          this.mainTotal.amount += sellRate;
          this.mainTotal.tax += tax;
        }
      }

      this.mainReservation.rooms.push(body);
    }
  }

  getSellRate(perDayPretaxRate: number) {
    if (this.taxInclusive) {
      if (this.formData.taxInclusive) {
        // if hotel is configured as tax inclusive
        return perDayPretaxRate;
      } else {
        return this.backwardTaxCalculation(perDayPretaxRate);
      }
    } else {
      return perDayPretaxRate;
    }
  }

  getRatePlan(
    roomId: string,
    mealplan: string,
    numOfGuest: number,
    perDayPretaxRate?,
    perDayTax?
  ): IRateplan {
    let rateplan = [];
    for (const item of this.formData.roomtypesInfo) {
      if (item.roomId === roomId) {
        rateplan = item.rateplans.filter((ele) => {
          return ele.mealplan === mealplan && ele.numOfGuest === numOfGuest;
        });
      }
    }
    if (perDayPretaxRate) {
      rateplan[0].perDayPretaxRate = perDayPretaxRate;
      rateplan[0].perDayTax = perDayTax;
    }
    return JSON.parse(JSON.stringify(rateplan[0]));
  }

  submit() {
    if (this.leadsProps?.status && !this.leadsProps.valid) {
      this.openWarningDialog('Please enter the required feilds');
      return;
    }

    if (this.leadsProps?.status) {
      this.groupName = this.guestInfo.firstName;
    }

    if (!this.groupName || !this.guestInfo.firstName) {
      this.openWarningDialog('Please enter the required feilds');
      return;
    }

    this.mainReservation = this.getRequestBody();

    this.roomFormArray.controls.forEach((e) => {
      const row: IRoomInfo = e.value;
      if (row.num >= 1) {
        this.addRoom(row);
      }
    });

    if (!this.mainReservation.rooms.length) {
      this.openWarningDialog('Please select atleast 1 room');
      return;
    }

    const dialogBody = {
      title: this.mode === 'modify' ? 'Modify Booking' : 'Create Booking',
      body: `Are you sure you want to submit ?`,
      confirmBtn: 'Yes',
      cancelBtn: 'Cancel',
    };

    const dialogRef = this.dialog.open(DialogComponent, { data: dialogBody });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'true') {
        const groupId = this.payload?.groupId;
        const reservations = [this.mainReservation];
        
        if (this.leadsProps?.status && ['blocked', 'confirmed'].includes(this.leadsProps.status)) {
          reservations[0].createLead = true;
          reservations[0].createdBy = this.leadsProps.createdBy;
          reservations[0].expirationDate = this.leadsProps.expirationDate;
          reservations[0].leadId = this.leadsProps.leadId;
        }
      
        if (this.leadsProps?.status === 'tentative') {
          this.reservationPayload.emit(this.getLeadsInfo(reservations));
        } else {
          if (this.newFile) {
            const ts = new Date().getTime();
            this.userService.uploadFile(this.newFile, `${this.hotelId}/${ts}-${this.newFile.name}`)
              .subscribe(
                (img) => {
                  reservations[0]['pms_payment']['pre_payment_info']['attachment'] = img.url;
                  this.createBooking(reservations, groupId);
                })
          } else {
            this.createBooking(reservations, groupId)
          }
        }
      }
    });
  }

  createBooking(reservations, groupId){
    this.reser.groupBooking(reservations, this.groupName, groupId).subscribe((data) => {
              if (data?.error) {
                return;
              }
              if (data) {
                if (this.leadsProps?.status) {
                  this.reservationPayload.emit(this.getLeadsInfo(reservations));
                } else {
                  this.bookingConfirmedMsg(data[0].bookingId);
                }
              }
            });
  }

  bookingConfirmedMsg(bookingId: string) {
    let dialogBody = {};
    if (this.mode === 'modify') {
      dialogBody = {
        title: 'Booking Modified !',
        body: `Your booking has been modified`,
        confirmBtn: 'OK',
      };
    } else {
      dialogBody = {
        title: 'Booking Confirmed !',
        body: `Your booking has been confirmed with booking ID - ${bookingId}`,
        confirmBtn: 'OK',
      };
    }

    const dialogRef = this.dialog.open(DialogComponent, { data: dialogBody, width: '400px',
      height: '160px' });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'true' || result === undefined) {
        this.showBookings.emit(true);
      }
    });
  }

  changeRoomRate(index: number) {
    const rowControl = this.roomFormArray.controls[index] as FormGroup;
    this.paxSelected = rowControl.value.pax;
    const roomInfo: IRoomInfo = rowControl.value;

    for (const item of this.roomTypeInfo[roomInfo.roomId].rateplans) {
      if (
        item.numOfGuest === roomInfo.pax &&
        item.mealplan === roomInfo.mealplan
      ) {
        rowControl.controls.childpax.setValue(0);
        const tempItem = this.copyObj(item);
        rowControl.controls.rate.setValue(tempItem.perDayPretaxRate);
        const rateplan = this.copyObj(rowControl.controls.rateplan.value);
        rateplan.perDayPretaxRate = tempItem.perDayPretaxRate;
        rateplan.perDayTax = rowControl.value.taxInclusive? 0 : tempItem.perDayTax;
        rowControl.controls.rateplan.setValue(rateplan);
        return;
      }
    }
    
  }

  addExtraChild(index: number) {
    const rowControl = this.roomFormArray.controls[index] as FormGroup;
    const roomInfo: IRoomInfo = rowControl.value;

    for (const item of this.roomTypeInfo[roomInfo.roomId].rateplans) {
      if (
        item.numOfGuest === roomInfo.pax &&
        item.mealplan === roomInfo.mealplan
      ) {
        const tempItem = this.copyObj(item);
        this.getTax(tempItem.perDayPretaxRate + (tempItem.extraChildRate * rowControl.value.childpax), index);
        rowControl.controls.rate.setValue(tempItem.perDayPretaxRate + (tempItem.extraChildRate * rowControl.value.childpax));
        return;
      }
    }
    
  }

  calculateTotal() {
    let amount = 0;
    let tax = 0;
    const nights = this.getNights();

    for (const item in this.roomTypeInfo) {
      if (item in this.roomTypeInfo) {
        this.roomTypeInfo[item].newAvailRooms =
          this.roomTypeInfo[item].availableRooms;
      }
    }

    (this.roomFormArray.value as Array<any>).forEach(
      (row: IRoomInfo, index) => {
        if (row.num > 0) {
          this.roomTypeInfo[row.roomId].newAvailRooms -= row.num;
          if (this.roomTypeInfo[row.roomId].newAvailRooms < 0) {
            this.roomFormArray.controls[index]['controls'].num.setValue(0);
            this.dialog.open(DialogComponent, {
              data: {
                title: 'WARNING !',
                body: `You have selected the max no. of available rooms for ${row.name}`,
                confirmBtn: 'Ok, I understand',
              },
              height: '160px',
            });
            return;
          }
          amount += (row.rateplan.perDayPretaxRate + row.rateplan.perDayTax) * row.num * nights;
          tax += row.rateplan.perDayTax * row.num * nights;
        }
      }
    );

    return { amount, tax };
  }

  rateplanChange(index: number) {
    const fb: FormGroup = this.roomFormArray.controls[index] as FormGroup;
    const obj: IRoomInfo = fb.value;
    const rp = this.getRatePlan(obj.roomId, obj.mealplan, obj.pax);
    const rate = obj.taxInclusive
      ? rp.perDayPretaxRate + rp.perDayTax
      : rp.perDayPretaxRate;
    fb.patchValue({
      rateplan: rp,
      rate,
    });
  }

  getNights() {
    return (
      (new Date(this.checkout).getTime() - new Date(this.checkin).getTime()) /
      (1000 * 3600 * 24)
    );
  }

  getTax(amount, index: number) {
    if (this.cachedTax[amount]) {
      this.setTaxRate(this.cachedTax[amount], index);
    } else {
      this.fetchTax(amount, index);
    }
  }

  fetchTax(amount: number, index: number) {
    this.reser.fetchtax(this.hotelId, amount).subscribe((data) => {
      if (!data) {
        return;
      }

      this.cachedTax[amount] = data;
      this.setTaxRate(data, index);
    });
  }

  taxInclusiveToggle(checked: boolean) {
    this.taxInclusive = checked;
    this.roomFormArray.controls.forEach((room: FormGroup, index: number) => {
      room.controls.taxInclusive.setValue(checked);
      this.onRateChange(index);
    });
  }

  setTaxRate(data: any, index: number) {
    const rowControl = (this.roomFormArray.controls[index] as FormGroup)
      .controls;
    const rateplanVal = JSON.parse(JSON.stringify(rowControl.rateplan.value));
    rateplanVal.perDayPretaxRate = data.tax_inclusive
      ? data.pretax_amount + data.rounded_tax
      : data.pretax_amount;
    rateplanVal.perDayTax = rowControl.taxInclusive.value ? 0 : data.tax.total;
    rowControl.rateplan.setValue(rateplanVal);
  }

  backwardTaxCalculation(perdaycost: number): number {
    let pretaxRate = 0.0;
    let tax = 0.0;
    if (this.config?.indiaTaxSlab) {
      if (perdaycost <= 8400) {
        pretaxRate = perdaycost / 1.12;
        tax = perdaycost - pretaxRate;
      } else if (perdaycost <= 8850 && perdaycost > 8400) {
        pretaxRate = 7500.0;
        tax = pretaxRate * 0.12;
      } else {
        pretaxRate = perdaycost / 1.18;
        tax = perdaycost - pretaxRate;
      }
    } else {
      pretaxRate = perdaycost;
    }
    return pretaxRate;
  }

  getRequestBody() {
    const nights = this.getNights();
    const total = this.total;

    const preTax = total.amount - total.tax;
    const bookingId =
      this.hotelId.replace(/-/g, '').slice(0, 3).toUpperCase() +
      (Math.random() * 10000000000).toFixed();
    var prepayment_info = null
    if (this.prePaidAmount !== 0){
      prepayment_info = {
        amount : this.prePaidAmount,
        bill_to : this.billtoForPrePaid,
        comments : this.commentsPrepaid,
        payment_mode : this.prePaymentMode,
        attachment : this.attachment
      }
    }
    const reservation = {
      pms_payment: {
        payment_mode: this.paymentMode,
        gstNumber: null,
        bill_to: this.billTo,
        address: null,
        pre_payment_info: prepayment_info
      },
      payload: '',
      guest: this.guestInfo,
      segment: this.leadsProps?.status ? 'Leads' : 'Groups',
      hotelId: this.hotelId,
      bookingId,
      bookedOn: '',
      source: 'PMS',
      amount: {
        toBePaidByCustomer: total.amount,
        tax: total.tax,
        extraCharges: 0,
        serviceName: null,
        otaToPayHotel: '',
        netOfCommissionAfterTax: total.amount,
        amountBeforeTax: preTax,
        amountAfterTax: total.amount,
        netOfCommissionBeforeTax: preTax,
        commission: 0,
        hotelToPayOTA: '',
        extraServiceCharges: [],
      },
      checkin: this.checkin,
      checkout: this.checkout,
      analytics_data: {},
      rooms: [],
      action: 'book',
      pah: 1,
      channel: 'PMS',
      sourceFields: { hotelCode: this.hotelId },
      bookedBy: this.bookedBy,
      specialRequests: this.specialRequests,
      user: this.user,
      city: this.city,
      country: this.country
    };

    if(this.paymentMode == "Prepaid"){
      reservation.pah = 0;
    }

    if (this.mode === 'modify') {
      reservation.action = 'modify';
      reservation.bookedOn = this.payload.bookedOn;
      reservation.bookingId = this.payload.bookingId;
      reservation.pah = this.payload.pah;
      // reservation.specialRequests = this.payload.specialRequests;
    }

    return reservation;
  }

  onRateChange(index: number, input?: boolean) {
    if (input) {
      clearTimeout(this.rateChangeTimer);
      const rate = (this.roomFormArray.controls[index] as FormGroup).controls
        .rate.value;
      this.rateChangeTimer = setTimeout(() => this.getTax(rate, index), 800);
    } else {
      const rate = (this.roomFormArray.controls[index] as FormGroup).controls
        .rate.value;
      this.getTax(rate, index);
    }
  }

  copyObj(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  openWarningDialog(msg: string) {
    this.dialog.open(DialogComponent, {
      data: {
        title: 'WARNING',
        body: msg,
        confirmBtn: 'Ok',
      },
    });
  }

  getLeadsInfo(reservation: any[]): ILeadsInfo {
    const bookingIds = [];
    const pax: number[] = [];
    const nights = this.getNights();

    reservation.forEach((r) => {
      bookingIds.push(r.bookingId);
      r.rooms.forEach((room) => {
        pax.push(room.occupancy.adults);
      });
    });

    const res: ILeadsInfo = {
      amount: this.total.amount,
      guestInfo: reservation[0].guest,
      checkIn: reservation[0].checkin,
      checkOut: reservation[0].checkout,
      bookingIds,
      pax,
      nights,
      reservations: reservation,
    };

    return res;
  }

  fileChange(event){
    this.newFile = event.files[0];
  }
}
