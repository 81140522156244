<div>
    <div class="d-flex justify-content-between">
        <h2>{{'companies.title' | translate}}</h2>
        <div class="d-flex">
            <button *ngIf="searchMode" (click)="getCompanies(); searchMode=false"
            mat-stroked-button class="active small-btn">
                Back
            </button>
            <button mat-stroked-button class="active heading-btn" (click)="addCompany()">
                {{'companies.add_company_btn' | translate}}
            </button>
            <button mat-stroked-button class="active heading-btn" routerLink="/city-ledger/{{hotelId}}">
                {{'companies.city_ledger_btn' | translate}}
            </button>
            <button mat-stroked-button class="active heading-btn" routerLink="/ageing-ledger/{{hotelId}}">
                Ageing Report
            </button>
            <div class="upload-btn-wrapper">
                <button
                  mat-stroked-button
                  class="active heading-btn"
                >
                  {{ "guest.upload_btn" | translate }}<mat-icon>upload</mat-icon>
                </button>
                <input
                  type="file"
                  name="documents"
                  accept=".xls"
                  (change)="uploadFile($event.target)"
                />
            </div>
            <div class="search ml-1">
                <form [formGroup]="search" (ngSubmit)="onSearch(search)">
                    <input id="search-bar" type="text" placeholder="{{'companies.search' | translate}} . . ."
                    formControlName="searchInput">
                    <button type="submit" mat-icon-button>
                        <mat-icon>search</mat-icon>
                    </button>
                </form>
            </div>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="table-container">
        <table class="table table-bordered ">
            <thead class="thead-dark">
                <tr>
                    <th>{{'companies.name' | translate}}</th>
                    <th>{{'companies.gst/vat' | translate}}</th>
                    <th>{{'companies.total_billed' | translate}}</th>
                    <th>{{'companies.total_outstanding' | translate}}</th>
                    <th>{{'companies.invoice' | translate}}</th>
                    <th>{{'companies.payments' | translate}}</th>
                    <th>{{'companies.action' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let company of companiesData">
                    <td [matMenuTriggerFor]="companyDetails">{{company.name}} &#9432;</td>
                    <td>{{company.tax_num}}</td>
                    <td>{{company.total_billed | currency: currencyCode}}</td>
                    <td>{{company.total_outstanding | currency: currencyCode}}</td>
                    <td [matMenuTriggerFor]="invoices"
                        (menuOpened)="getInvoices(company)"
                        [ngStyle]="{'color': '#3c8dbc'}">
                        {{'companies.details' | translate}}
                    </td>
                    <td>{{company.payments}}</td>
                    <td class="action">
                        <mat-icon (click)="editCompany(company)">edit</mat-icon>
                        <mat-icon (click)="deleteCompany(company.id, company.name)">delete</mat-icon>
                    </td>
                    <mat-menu #companyDetails="matMenu">
                        <div class="menu-container" [ngStyle]="{'width.px': 300, 'pointer-events': 'none'}">
                            <h3>{{'companies.company_details' | translate}}</h3>
                            <mat-divider></mat-divider>
                            <div class="row">
                                <small class="col-sm-5">{{'companies.contact_person' | translate}}</small>
                                <small class="col-sm-7 value value">: {{company.contact_person}}</small>
                            </div>
                            <div class="row">
                                <small class="col-sm-5">{{'companies.phone' | translate}}</small>
                                <small class="col-sm-7 value">: {{company.phone}}</small>
                            </div>
                            <div class="row">
                                <small class="col-sm-5">{{'companies.email' | translate}}</small>
                                <small class="col-sm-7 value">: {{company.email}}</small>
                            </div>
                            <div class="row">
                                <small class="col-sm-5">{{'companies.address' | translate}}</small>
                                <small class="col-sm-7 value">: {{company.address}}</small>
                            </div>
                            <div class="row">
                                <small class="col-sm-5">{{'companies.chain' | translate}}</small>
                                <small class="col-sm-7 value">: {{company.chain}}</small>
                            </div>
                        </div>
                    </mat-menu>
                    <mat-menu #invoices="matMenu">
                        <ng-container *ngIf="company.hasOwnProperty('invoices') && !company.invoices?.length">
                            <div class="p-2">
                                No Data
                            </div>
                        </ng-container>
                        <ng-container *ngIf="company?.invoices && company?.invoices.length">
                            <div *ngFor="let invoice of company?.invoices; let i=index;" class="menu-container"
                            [ngStyle]="{'background-color': i%2 == 0 ? '#f2f2f2': '#fff', 'height': '66px', 'width': '100%'}">
                            <div class="row">
                                <small class="col-sm-8 text-truncate">
                                    {{invoice.guest_name}}
                                </small>
                                <small class="col-sm-4">
                                    {{'companies.room_no' | translate}} - {{invoice.room_num}}
                                </small>
                                
                            </div>
                            <div class="row">
                                <small class="col-sm-8">
                                    {{invoice.checkin | date: 'dd MMM YY'}} - {{invoice.checkout | date: 'dd MMM YY'}}
                                </small>
                                <small class="col-sm-4 text-right">
                                    <button class="btn btn-info btn-sm" (click)="getInvoice(invoice.invoice_id)">
                                        {{'companies.invoice' | translate}}
                                    </button>
                                </small>
                            </div>
                        </div>
                        </ng-container>
                    </mat-menu>
                </tr>
                <tr class="bottom-row">
                    <td colspan="7">
                        <div class="bottom-td">
                            <div *ngIf="companiesData?.length > 0; else notFoundBlock">
                                {{'companies.showing' | translate}} {{(currentPage * 50) + 1}} {{'companies.to' | translate}} {{(currentPage * 50) + entries}} {{'companies.of' | translate}} {{totalEntries}}
                                <span *ngIf="searchMode; else entriesBlock">
                                    results
                                </span>
                                <ng-template #entriesBlock>
                                    <span>entries</span>
                                </ng-template>
                            </div>
                            <div>
                                <ng-template #notFoundBlock>
                                    <!-- No results found -->
                                </ng-template>
                            </div>

                            <div class="pagination">
                                <button mat-stroked-button (click)="getCompanies(currentPage - 1)"
                                [disabled]="(currentPage - 1) < 0">
                                    {{'companies.previous_btn' | translate}}
                                </button>
                                <button *ngFor="let page of [].constructor(totalPages+1); let i = index"
                                    class="btn btn-small page-num" (click)="getCompanies(i)"
                                    [ngClass]="{'active': currentPage===(i)}">
                                    {{i+1}}
                                </button>
                                <button mat-stroked-button (click)="getCompanies(currentPage + 1)"
                                [disabled]="(currentPage + 1) > totalPages">
                                    {{'companies.next_btn' | translate}}
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
                
            </tbody>
        </table>
    </div>
</div>