<div class="container">
  <div class="form">
    <div class="row mb-4">
      <div class="form-group col-sm-3" *ngIf="!leadsProps?.status">
        <label for="groupName"
          >{{ "group_booking.group_name" | translate }}
          <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control form-control-sm"
          placeholder="Enter group name"
          [(ngModel)]="groupName"
        />
      </div>
      <div class="form-group col-sm-3">
        <label for="checkin"
          >{{ "group_booking.check_in" | translate }}
          <span class="text-danger">*</span></label
        >
        <input
          type="date"
          class="form-control form-control-sm"
          min="{{ config?.currentDate }}"
          [(ngModel)]="checkin"
          (change)="onDateChange()"
        />
      </div>
      <div class="form-group col-sm-3">
        <label for="checkin"
          >{{ "group_booking.check_out" | translate }}
          <span class="text-danger">*</span></label
        >
        <input
          type="date"
          class="form-control form-control-sm"
          min="{{ checkin }}"
          [(ngModel)]="checkout"
          (change)="onDateChange()"
        />
      </div>
      <div
        class="col-sm-3 d-flex flex-column justify-content-center align-items-center bg-light-blue"
      >
        <div class="text--primary">
          {{ "group_booking.total_amount" | translate }}
        </div>
        <div class="text--primary h6">
          {{ total.amount | currency : formData?.currencyCode }}
        </div>
      </div>
    </div>

    <div class="row">
      <div [ngClass]="{'offset-sm-9': formData?.allowExtraChild, 'offset-sm-7': !formData?.allowExtraChild}" class="col-sm-2">
        <label class="d-flex align-items-center text-nowrap">
          {{ "group_booking.tax_inclusive" | translate }}
          <input
            type="checkbox"
            class="form-control form-control-sm ml-1"
            [checked]="taxInclusive"
            (change)="taxInclusiveToggle($event.target.checked)"
          />
        </label>
      </div>
    </div>

    <div class="row mb-2 bold">
      <div class="col-sm-2">{{ "group_booking.room_type" | translate }}</div>
      <div class="col-sm-1">{{ "group_booking.#rooms" | translate }}</div>
      <div class="col-sm-2">{{ "group_booking.#guests/room" | translate }}</div>
      <div class="col-sm-2" [hidden] = "!formData?.allowExtraChild">{{ "group_booking.#child/room" | translate }}</div>
      <div class="col-sm-2">{{ "group_booking.rateplan" | translate }}</div>
      <div class="col-sm-2">{{ "group_booking.rate/day" | translate }}</div>
      <!-- <div class="col-sm-2">Tax Inclusive</div> -->
    </div>

    <form class="box" [formGroup]="bookingsForm" *ngIf="formData">
      <ng-container formArrayName="rooms">
        <div
          class="row mb-2 mt-2"
          *ngFor="
            let type of bookingsForm.controls['rooms']['controls'];
            let i = index
          "
        >
          <ng-container [formGroupName]="i">
            <div
              class="col-sm-2 bold text--primary d-flex align-items-center justify-content-between"
            >
              {{ type.value.name }}
            </div>

            <div
              class="col-sm-1"
            >
              <select
                class="form-control form-control-sm"
                formControlName="num"
              >
                <!-- <ng-container *ngIf="mode==='modify' && roomTypeInfo[type.value.roomId]?.availableRooms <= 0; else listNumBlock">
                  <option
                    *ngFor="
                      let num of [].constructor(type.value.num + 1);
                      let j = index
                      "
                      [ngValue]="j"
                    >
                    {{ j }}
                  </option>
                </ng-container> -->
                  <option
                  *ngFor="
                    let num of [].constructor( roomTypeInfo[type.value.roomId]?.availableRooms + 1 );
                    let j = index
                    "
                    [ngValue]="j"
                  >
                    {{ j }}
                  </option>
          
              </select>
            </div>

            <div class="col-sm-2">
              <select
                class="form-control form-control-sm"
                formControlName="pax"
                (change)="changeRoomRate(i)"
              >
                <option
                  *ngFor="
                    let num of roomTypeInfo[type.value.roomId]?.numGuestsAllowed
                  "
                  [ngValue]="num"
                >
                  {{ num }}
                </option>
              </select>
            </div>

            <div class="col-sm-2" [hidden] = "!formData?.allowExtraChild">
              <select 
                class="form-control form-control-sm"
                formControlName="childpax"
                (change)="addExtraChild(i)"
              >
                <option
                  *ngFor="
                    let num of roomTypeInfo[type.value.roomId]?.numGuestsAllowed
                  "
                  [ngValue]="num - paxSelected" [hidden]="(num - paxSelected) < 0"
                >
                  {{ num - paxSelected}}
                </option>
              </select>
            </div>
            <div class="col-sm-2">
              <select
                class="form-control form-control-sm"
                formControlName="mealplan"
                (change)="rateplanChange(i)"
              >
                <option
                  *ngFor="
                    let plan of roomTypeInfo[type.value.roomId]?.mealplans
                  "
                  [value]="plan"
                >
                  {{ plan }}
                </option>
              </select>
            </div>

            <div class="col-sm-2">
              <input
                type="number"
                class="form-control form-control-sm"
                formControlName="rate"
                (input)="onRateChange(i, true)"
                min="0"
              />
            </div>

            <!-- <div class="d-flex align-items-center col-sm-1"
                          [ngClass]="{'disabled': mode === 'modify'}">
                          <input 
                              type="checkbox" 
                              class="form-control form-control-sm" 
                              formControlName="taxInclusive"
                              (input)="onRateChange(i)">
                      </div> -->

            <div class="col-sm-1 d-flex gap-2 align-items-center">
              <mat-icon
                class="text--primary"
                (click)="pushRoomItem(type.value.roomId, i)"
                >add_circle_outline</mat-icon
              >
              <mat-icon
                *ngIf="roomTypeInfo[type.value.roomId].count > 1"
                class="text-danger"
                (click)="deleteRoomItem(type.value.roomId, i)"
              >
                delete
              </mat-icon>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </form>

    <div class="row mt-3">
      <div class="form-group col-sm-4">
        <label for="paymentMode">{{
          "group_booking.payment_mode" | translate
        }}</label>
        <select class="form-control form-control-sm" [(ngModel)]="paymentMode">
          <option *ngFor="let mode of formData?.paymentModes" [value]="mode">
            {{ mode }}
          </option>
        </select>
      </div>

      <div class="form-group col-sm-4">
        <label for="billTo">{{ "group_booking.bill_to" | translate }}</label>
        <input
          type="text"
          list="companies"
          class="form-control form-control-sm"
          [(ngModel)]="billTo"
        />
        <datalist id="companies">
          <option [value]="guestInfo.firstName"></option>
          <option
            *ngFor="let company of formData?.companiesData"
            [value]="company.companyName"
          ></option>
        </datalist>
      </div>

      <div class="form-group col-sm-4">
        <label for="bookedBy"
          >{{ "group_booking.booked_by" | translate }}
        </label>
        <input
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="bookedBy"
        />
      </div>
    </div>

    <div class="row mt-3">
      <div class="form-group col-sm-4">
        <label for="guestName"
          >{{ "group_booking.guest_name" | translate }}
          <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="guestInfo.firstName"
          placeholder="Enter guest name"
        />
      </div>
      <div class="form-group col-sm-4">
        <label for="billTo">{{ "group_booking.email" | translate }}</label>
        <input
          type="email"
          class="form-control form-control-sm"
          [(ngModel)]="guestInfo.email"
          placeholder="Enter email"
        />
      </div>
      <div class="form-group col-sm-4">
        <label for="phone">{{ "group_booking.phone" | translate }}</label>
        <input
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="guestInfo.phone"
          placeholder="Enter phone no."
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="form-group col-sm-4">
        <label for="City"
          >City
        </label>
        <input
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="city"
          maxlength="64"
        />
      </div>

      <div class="form-group col-sm-4">
        <label for="Country"
          >Country
        </label>
        <input
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="country"
          maxlength="64"
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="form-group col-sm-8">
        <label for="specialRequests"
          >{{ "group_booking.special_requests" | translate }}
        </label>
        <input
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="specialRequests"
          placeholder="Ex : Need Extra Pillow."
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="form-group col-sm-12">
        <label for="advancePaymentHeading"><u>Advance Payment Info</u></label>
      </div>
    </div>
    <div class="row mt-1">
      <div class="form-group col-sm-2">
        <label for="prePaidAmount"
          >{{ "group_booking.amount_paid" | translate }}
        </label>
        <input
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="prePaidAmount"
        />
      </div>
      <div class="form-group col-sm-2">
        <label for="prePaymentMode">{{
          "group_booking.payment_mode" | translate
        }}</label>
        <select class="form-control form-control-sm" [(ngModel)]="prePaymentMode">
          <option *ngFor="let mode of formData?.paymentModes" [value]="mode">
            {{ mode }}
          </option>
        </select>
      </div>
      <div *ngIf="prePaymentMode == 'Bill to Company'" class="form-group col-sm-2">
        <label for="billtoForPrePaid"
          >{{ "stayview.reservation.bill_to_company" | translate }}
        </label>
        <input
          type="text"
          list="billtoForPrePaid"
          class="form-control form-control-sm"
          [(ngModel)]="billtoForPrePaid"
        />
        <datalist id="billtoForPrePaid">
          <option
            *ngFor="let company of formData?.companiesData"
            [value]="company.companyName"
          ></option>
        </datalist>
      </div>
      <div class="form-group col-sm-3">
        <label for="commentsPrepaid"
          >{{ "stayview.reservation.comments" | translate }}
        </label>
        <input
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="commentsPrepaid"
        />
      </div>
      <div class="col-sm-3">
        <label for="details">{{'stayview.reservation.attachment' | translate}}</label>
          <div class="common_browse_file">
            <input type="hidden" class="form-control form-control-sm" value="" name="" maxlength="30">
            <input type="file" class="custom-file-input form-control form-control-sm" name="busi-for-doc"
              accept="image/*" (change)="fileChange($event.target)">
            <label class="doc-name form-control-sm">
              <span>{{newFile?.name}}</span>
            </label>
          </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="offset-sm-8 col-sm-4 text-right">
        <button
          mat-raised-button
          type="submit"
          class="active"
          (click)="submit()"
          [disabled] = "prePaymentMode == 'Bill to Company' && !billtoForPrePaid"
        >
          {{ "group_booking.submit" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
