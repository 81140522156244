<div id="invoice-POS">
    <span id="close-button" (click)="closeDialog();">
        <mat-icon>cancel</mat-icon>
    </span>
    <div id="top">
        <div class="logo"></div>
        <div class="info">
            <ng-container *ngFor="let line of invoiceHeading">
                <h2>{{line}}</h2>
            </ng-container>
            <div [ngStyle]="{'display': 'flex', 'flex-direction': 'column'}">
                <ng-container *ngFor="let line of invoiceAddress">
                    <span id="sub-heading">
                        {{line}}
                    </span>
                </ng-container>
            </div>
        </div>
        <!--End Info-->
    </div>

    <div id="mid">
        <div class="mid-info">
            <ng-container *ngIf="data?.order?.invoice">
                <span class="h6">
                    {{'pos.bill_no' | translate}}: {{data.order.invoice.num}}
                </span>

                <span class="h6" style="margin: 0;">
                    {{'pos.details' | translate}}: {{data.order.invoice.details}}
                </span>
            </ng-container>
            
            <ng-container *ngIf="!data?.order?.invoice">
                <span class="h6">
                    {{'pos.order_no' | translate}}: {{data.order?.order_id}}
                </span>

                <span class="h6" style="margin: 0;">
                    {{'pos.details' | translate}}: {{data.order?.details}}
                </span>
            </ng-container>
           
        </div>
        <span *ngIf="data?.order?.invoice" class="h6">
            {{'pos.date' | translate}}: {{data.order.invoice.billed_date | date: 'dd MMM h:mm a'}}
        </span>
        <span *ngIf="!data?.order?.invoice" class="h6">
            {{'pos.date' | translate}}: {{data.order?.date | date: 'dd MMM h:mm a'}}
        </span>
    </div>
    <!--End Invoice Mid-->

    <div id="bot">

        <div id="table">
            <table>
                <tr class="tabletitle dashed-border">
                    <td class="table-heading">
                        {{'pos.service' | translate}}
                    </td>
                    <td class="table-heading text-center">
                        {{'pos.qty' | translate}}
                    </td>
                    <td *ngIf="data?.order?.invoice"
                        class="table-heading text-right">
                        {{'pos.rate' | translate}}
                    </td>
                    <td *ngIf="data?.order?.invoice"
                        class="table-heading text-right">
                        {{'pos.amount' | translate}}
                    </td>
                </tr>

                <tr *ngFor="let item of data.order.services_used.data" 
                    class="service">
                    <td class="tableitem">
                        <p class="itemtext">{{item.service_name}}</p>
                    </td>
                    <td class="tableitem">
                        <p class="itemtext text-center">{{item.quantity}}</p>
                    </td>
                    <td *ngIf="data?.order?.invoice"
                        class="tableitem">
                        <p class="itemtext text-right">{{item.rate}}</p>
                    </td>
                    <td *ngIf="data?.order?.invoice"
                        class="tableitem">
                        <p class="itemtext text-right">{{item.amount}}</p>
                    </td>
                </tr>

                <tr *ngIf="data?.order?.invoice"
                    class="service bottom">
                    <td class="tableitem">
                        {{'pos.total' | translate}}
                    </td>
                    <td class="tableitem text-center">
                        {{data.order.services_used.total_quantity}}
                    </td>
                    <td></td>
                    <td class="tableitem text-right">
                        {{data.order.services_used.amount_sum}}
                    </td>
                </tr>

                <tr *ngIf="data?.order?.invoice"
                    class="service bottom">
                    <td class="tableitem">
                        {{'pos.discount' | translate}}
                    </td>
                    <td></td>
                    <td></td>
                    <td class="tableitem text-right">
                        {{data.order.services_used.total_discount}}
                    </td>
                </tr>

                <ng-container *ngIf="data?.order?.invoice">
                    <tr *ngFor="let tax of data.order.tax.totalTaxBreakup" 
                        class="service bottom">
                        <td class="tableitem">
                            {{tax.key}}
                        </td>
                        <td></td>
                        <td></td>
                        <td class="tableitem text-right">
                            {{tax.value}}
                        </td>
                    </tr>
                </ng-container>

                <tr *ngIf="data?.order?.invoice"
                    class="service bottom" [ngStyle]="{'font-weight': '550'}">
                    <td class="tableitem">
                        {{'pos.net_amount' | translate}}
                    </td>
                    <td></td>
                    <td></td>
                    <td class="tableitem text-right">
                       {{data.config.currencyCode}} {{data.order.invoice.total}}
                    </td>
                </tr>

            </table>
        </div>
        <!--End Table-->
    </div>
    <div id="footer">
        <p>{{data.config.invoiceFooter}}</p>
    </div>
</div>