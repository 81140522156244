<div class="displayFlex">
  <h2>{{floorView ? 'Floor View' : 'Rooms View'}}</h2>
  <div class="displayFlex" 
    [ngStyle]="{'width': '40%'}"
    *ngIf="summary">
    <div>
      <span class="summary">
          {{'rooms.occupied' | translate}} :
          <span class="notification">
            {{summary?.occupied}}
          </span>
      </span>
    </div>
    <div>
      <span class="summary">
          {{'rooms.available' | translate}} :
          <span class="notification">
            {{summary?.available}}
          </span>
      </span>
    </div>
    <div>
      <span class="summary">
        {{'rooms.complimentary' | translate}} :
          <span class="notification">
            {{summary?.complementary}}
          </span>
      </span>
    </div>
    <div>
      <span class="summary">
        {{'rooms.maintenance' | translate}} :
          <span class="notification">
            {{summary?.outoforder}}
          </span>
      </span>
    </div>
  </div>
</div>
<mat-divider></mat-divider>

<div class="room-view-container">
  <div class="nav-row">
    <div class="colorDiv" *ngIf="globals.innerWidth > 1024">
      <span class="status">
        <div class="color-code" style="background-color:#f1acac"></div>{{'rooms.assigned' | translate}}
      </span>
      <span class="status">
        <div class="color-code" style="background-color:#1ebc38cb"></div>{{'rooms.checked_in' | translate}}
      </span>
      <span class="status">
        <div class="color-code" style="background-color:#ac0000"></div>{{'rooms.checking_out' | translate}}
      </span>
      <span class="status">
        <div class="color-code" style="background-color:#6d6d6b"></div>{{'rooms.maintenance' | translate}}
      </span>
      <span class="status">
        <div class="color-code" style="background-color:rgb(213 221 63 / 80%)"></div>Complimentary
      </span>
    </div>

    <button mat-raised-button class="active small-btn" data-toggle="tooltip" data-placement="top" title="Floor View"
      (click)="toggleFloorView()">
      {{floorView ? 'Rooms View' : 'Floor View'}}
    </button>

    <button mat-mini-fab class="active small-btn" data-toggle="tooltip" data-placement="top" title="Create Reservation"
      [ngStyle]="{'font-size': '31px', 'transform': 'scale(0.7)'}"
      routerLink="/reservation/{{hotelId}}" [queryParams]="{user: currentUser}">
      +
    </button>

    <button mat-mini-fab class="active small-btn ml-1" data-toggle="tooltip" data-placement="top" title="Expenses"
      [ngStyle]="{'transform': 'scale(0.7)'}" routerLink="/expenses/{{hotelId}}"
      [ngClass]="{'disable_button': !features?.live?.expense}"
      >
      <mat-icon>attach_money</mat-icon>
    </button>

    <button mat-icon-button (click)="changeDay('prev')">
      <mat-icon>arrow_back_ios</mat-icon>
    </button>

    <mat-form-field floatLabel="never" appearance="outline">
      <mat-label>{{date | date:'dd/MM/yy'}}</mat-label>
      <input matInput #ref [matDatepicker]="picker" (dateChange)="datePicked(ref.value)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <button mat-icon-button (click)="changeDay('next')">
      <mat-icon>arrow_forward_ios</mat-icon>
    </button>
  </div>

  <div class="room-view">
    <ng-container *ngFor="let item of roomViewData">
      <div class="room-block">
        <div class="room-type">
          <span class="room-name text-truncate">
            {{floorView ? (item.floor ? item.floor : 'No Floor') : item.roomTypeName}}
          </span>
          
          <div class="displayFlex" [ngStyle]="{'width': '80%'}">
            
            <div class="d-flex">
              <svg (click)="item.hidden = !item.hidden"
                class="collapse-svg"
                [ngClass]="{'rotate-180': item.hidden}"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  d="M416 352c-8.188 0-16.38-3.125-22.62-9.375L224 173.3l-169.4 169.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25C432.4 348.9 424.2 352 416 352z" />
              </svg>
  
              <div [matMenuTriggerFor]="menu" *ngIf="item.unassignedGuests?.length">
                <button *ngIf="item.unassignedGuests[0].guests?.length > 0"
                  class="active small-btn"
                  mat-raised-button matBadge="{{item.unassignedGuests[0].guests?.length}}" 
                  matBadgePosition="after" matBadgeColor="accent">
                  {{'rooms.assign' | translate}}
                </button>
  
                <mat-menu #menu="matMenu">
                  <div class="menu-container" *ngFor="let guest of item.unassignedGuests[0]?.guests; let ug = index;" 
                    [ngStyle]="{'background-color': ug%2 == 0 ? '#f2f2f2': '#fff'}">  
                    <div class="row" style="margin: auto;">
                      <div class="col-sm-7">
                        <h4>{{guest.guestName}}</h4>
                      </div>
  
                      <div class="col-sm-5" style="padding: 0;">
                        <small>{{guest.checkIn | date: 'dd MMM'}}  -  {{guest.checkOut | date: 'dd MMM'}}</small>
                      </div>
  
                    </div>  
                    <div class="row" style="margin: auto;">  
                      <div class="col-sm-4">
                        <small class="text-secondary">{{guest.source}}</small>
                      </div>
                      <div class="col-sm-3" style="padding: 0;">
                        <small style="font-weight: 450;">{{guest.total_price | currency: currencyCode}}</small>
                      </div>
                      <div class="col-sm-5">
                        <div class="row">
                          <div class="col-sm-6" style="padding: 0;">
                            <button class="btn btn-info btn-sm"
                            (click)="goToEdit(guest.roomAllocationId, 'Unassigned', guest.bookingId, guest.source)">
                              {{'rooms.edit' | translate}}
                            </button>
                          </div>
                          <div class="col-sm-6" style="padding: 0;">
                            <button class="btn btn-info btn-sm"
                              (click)="selectGuest({bookingId:guest.bookingId, roomAllocationId: guest.roomAllocationId, checkin:guest.checkIn, checkout:guest.checkOut})">
                              {{'rooms.assign' | translate}}
                            </button>
                          </div>
                        </div>
                      </div> 
                    </div>
                  </div> 
                </mat-menu> 
  
              </div>
            </div>

            <div class="displayFlex" [ngStyle]="{'width': '50%'}">
              <div>
                <span class="summary">
                    {{'rooms.occupied' | translate}} :
                    <span class="notification">
                      {{item.summary?.occupied}}
                    </span>
                </span>
              </div>
              <div>
                <span class="summary">
                    {{'rooms.available' | translate}} :
                    <span class="notification">
                      {{item.summary?.available}}
                    </span>
                </span>
              </div>
              <div>
                <span class="summary">
                    {{'rooms.complimentary' | translate}} :
                    <span class="notification">
                      {{item.summary?.complementary}}
                    </span>
                </span>
              </div>
              <div>
                <span class="summary">
                    {{'rooms.maintenance' | translate}} :
                    <span class="notification">
                      {{item.summary?.outoforder}}
                    </span>
                </span>
              </div>
            </div>

          </div>
        </div>
        <div class="room-data" [ngClass]="{'collapsed': item.hidden}">
        
          <div *ngFor="let room of item.rooms" class="room-box" 
            (dragover)="dragOver($event, true, room.roomNum)" (drop)="drop(room.roomNum, room.roomNumId)"
            (mouseover)="toggleMouseOver(true, room.roomNum)" (mouseout)="toggleMouseOver(false)"
            [ngStyle]="selectRoom && {'cursor': 'crosshair'}"
            [ngClass]="{'assign-hover': selectRoom && mouseOverRoom.value && mouseOverRoom.roomNum === room.roomNum && room.room_alloc === null}"
            (click)="roomBoxClick(room, item.roomTypeId, item.roomTypeName)">
            <span class="room_type_name" *ngIf="floorView">{{room.roomTypeName}}</span>
            <div class="tag-color"
              [ngClass]="{
                'AssignedClass': room.allocations?.length && room.allocations[0]?.status === 'Assigned',
                'CheckedInClass': room.allocations?.length && room.allocations[0]?.status === 'Checked in',
                'CheckingOutClass': room.allocations?.length &&  room.allocations[0]?.checkOut === currentDate && room.allocations[0]?.status === 'Checked in',
                'CompliClass': room.allocations?.length && room.allocations[0]?.status === 'Checked in' && room.allocations[0]?.businessSegment === 'Management Block',
                'MaintainenceClass': room.allocations?.length && room.allocations[0]?.status === 'Out of Order',
                'defaultBg': !room.allocations?.length,
                'red_border': room.allocations?.length && ((balances.hasOwnProperty(room.allocations[0]?.roomAllocationId) && balances[room.allocations[0].roomAllocationId] > 0 && room.allocations[0].status === 'Checked in')
                                                || (room.allocations[0]?.status === 'Assigned' && room.allocations[0]?.paymentMode === 'Pay at Hotel'))
                }">
              <div class="tag-data">

                <span>{{room.roomNum}}</span>
                <div class="d-flex justify-content-between align-items-baseline">

                  <ng-container *ngIf="features?.live?.showIotStatus && room?.iotStatus">
                    <mat-icon *ngIf="room?.iotStatus !== 'na'" 
                      class="d-flex justify-content-between align-items-center"
                      [ngClass]="{
                        'iot-status-on': room?.iotStatus === 'on',
                        'iot-status-off': room?.iotStatus === 'off',
                        'iot-status-disconnected': room?.iotStatus === 'disconnected'
                        }"
                        data-toggle="tooltip" data-placement="top" title="{{room?.iotStatus}}">bolt
                    </mat-icon>

                    <mat-icon *ngIf="room?.iotStatus === 'na'" 
                      class="d-flex justify-content-between align-items-center text-secondary" [ngStyle]="{'font-size': '15px'}"
                      data-toggle="tooltip" data-placement="top" title="Not configured">
                      close
                    </mat-icon>
                  </ng-container>

                  <div class="optional-icons">
                    <ng-container *ngFor="let icon of room.icons">
                        <ng-container *ngIf="icon.isSelected">
                            <img *ngIf="icon.name === 'Twin'" src="../../../assets/img/twin-beds.png" alt="">
                            <img *ngIf="icon.name === 'King'" src="../../../assets/img/bed.png" alt="">
                            <img *ngIf="icon.name === 'Smoking'" src="../../../assets/img/cigarette.png" alt="">
                            <img *ngIf="icon.name === 'View'" src="../../../assets/img/photo.png" alt="">
                        </ng-container>
                    </ng-container>
                </div>

                  <mat-icon id="broom" svgIcon='broom' *ngIf="room.housekeeping === 'dirty'"
                    (click)="cleanDirtyToggle(room.roomNumId, 'clean', room.roomNum)">
                  </mat-icon>

                  <mat-icon id="clean" svgIcon='clean' *ngIf="room.housekeeping === 'clean'"
                    (click)="cleanDirtyToggle(room.roomNumId, 'dirty', room.roomNum)">
                  </mat-icon>
                </div>

              </div>
            </div>

            <span *ngIf="room.allocations?.length"
              class="guest-name" 
              mat-button 
              (menuOpened)="allocationMenuOpened(room.allocations[0])"
              [matMenuTriggerFor]="menu"
              draggable="true" 
              (drag)="dragStart(room.roomNum, room.roomNumId, room.allocations[0])"
              (dragend)="dragEnd()">
              <span [ngStyle]="{'position': 'absolute', 'bottom': 0, 'text-overflow': 'ellipsis',
                    'white-space': 'nowrap', 'overflow': 'hidden', 'width': '100%', 'max-width': '13ch'}">
                {{room.allocations[0]?.guestName}}
              </span>
              
              <!-- SOURCE IMAGE -->
              <img *ngIf="room.allocations[0].checkIn !== room.allocations[0].checkOut && 
                sourceIconMapping.hasOwnProperty(room.allocations[0].source)"
                [src]="iconPath + sourceIconMapping[room.allocations[0].source]" class="source_img">
            </span>
            <mat-menu #menu="matMenu">

              <app-allocation-menu2 *ngIf="room.allocations?.length"
                [guestInfo]="room.allocations[0]"
                [currentData]="{startDate: date, currentDate: currentDate, room: room}"
                (refreshView)="getRoomView(date)"
                >
              </app-allocation-menu2>
            </mat-menu>
          </div>
  
        </div>
      </div>
    </ng-container>
    
  </div>
</div>