import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormControl, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DialogComponent } from '../../_shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Globals } from 'src/app/core/globals.service';
import { UserService } from 'src/app/core/user.service';
import { ReportsService } from 'src/app/core/reports.service';
import { CommunicationService } from 'src/app/core/communication.service';
import { FileService } from 'src/app/core/file.service';
import { saveAs } from 'file-saver-es';
import { StoreService } from 'src/app/core/store.service';
import { BookingDetailsComponent } from '../booking-details/booking-details.component';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  hotelId: string;
  currentUser: string;
  currencyCode: string;
  reportData:any;
  hotels:any;
  chainsData:any;
  inChians:any;
  taxLength :any;
  posLength :any;
  segmentLength :any;
  monthsLength:any;
  hotelsLength:any;
  paymentsLength:any;
  summaryShow:any;
  allPosLength:any;
  today =  new Date().toISOString().slice(0, 10);
  monthFirstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  lastMonthFirstDay = new Date(new Date().getFullYear(), new Date().getMonth()-1, 1);
  lastMonthLastDay = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
  tenDaysBeforeDate = new Date(new Date().setDate(new Date().getDate() - 10));
  oneWeekBeforeDate = new Date(new Date().setDate(new Date().getDate() - 7));
  thirtyDaysAfterDate = new Date(new Date().setDate(new Date().getDate() + 30));
  yestrdayDate = new Date(new Date().setDate(new Date().getDate() - 1));
  sevenDaysBeforeDate = new Date(new Date().setDate(new Date().getDate() - 7));
  toDate = this.today;
  fromDate = this.today;
  fromMonth: any;
  toMonth: any;
  maxMonth: any;
  reportName: any; 
  sectionKey: any; 
  option = 'Sent';
  selectReport = 'Download Report';
  pmsConfiguration:any;
  hotelChains : Array<String>= [];
  selectChain: any;
  selectPaymentType: any;
  selectOptionsType: any;
  selectMealPlan: any;
  selectStatus: any;
  selectState: any;
  selectFloor: any;
  selectUser: any;
  paymentTypes : Array<String>= [];
  users : Array<String>= [];
  mealplanList: Array<String>= [];
  dataSource: any;
  ContriGrandTotals:any;
  userPmsReportFeatures: any;
  updatedDataSource : any;
  groupSelect:any;
  singleDateSelect: any;
  pdfView = false;
  statusList = []; 
  stateList = []; 
  floorList = []; 
  sortType :any;
  sortReverse: any; 
  companyDetails = false;
  comapanyName:false;
  guestData:any;
  reportTabName: any;
  autoColumnName:any;
  
  
  excelDownloadList = [ 'Arrival Report', 'Departure Report', 'Item Consumption Report', 'Payments Report', 'Checkout based Accounting Report', 'POS Report', 'Expense Report', 'Room Status Report', 'Group Payments Report', 'Monthly P&L', 'Group P&L', 
    'City Ledger Payment Report', 'Payment Void Report', 'Company Performance', 'Police Enquiry Report', 'Room Wise Revenue Report', 'Date Wise Revenue Report', 'Group Date Wise Revenue Report', 'Monthly Performance Report', 'Night Audit Report', 'Hotel Sales Report',
    'Management Block Report', 'Out of Order Room Report', 'No Show Report', 'Maintenance Report', 'Daily Forecast Report', 'Contribution Report', 'Laundry Report'];
  
  pdfDownloadReports = ['Police Enquiry Report', 'Room Status Report']
  oneWeekTypes: Array<string> = ['Same Day Checkouts Report', 'Late Checkout Report', 'Early Checkin Report','Room Upgrade Report'];

  mtdTypes: Array<string> = ['Out of Order Room Report', 'Management Block Report', 'Hotel Sales Report', 'No Show Report', 'Company Performance', 'Payment Void Report', 'Expense Report', 'City Ledger Payment Report', 'Cancelled Reports', 'Guest Phone Number Difference',
   'Contribution Report','Reviews Tracking Report', 'Group Maintenance Report', 'Date Wise Revenue Report', 'Group Date Wise Revenue Report', 'Maintenance Report', 'Group IOT Device Monthly Status Report', 'Group Weekly Meeting Report', 'Hotel Weekly Meeting Report', 'Laundry Report', 'Group Laundry Report'];
  yesTypes: Array<string> = ['POS Report', 'Group Sales Report', 'IOT Occupancy Issues Report', 'Police Enquiry Report'];
  todayTypes: Array<string> = ['Item Consumption Report', 'Payments Report', 'Checkout based Accounting Report', 'Group Payments Report', 'Group Expense Report'];
  groupReports: Array<string> = ['Group Sales Report', 'Group P&L', 'Group Maintenance Report', 'Group Date Wise Revenue Report', 'Group IOT Occupancy Issues Report', 'Group IOT Device Monthly Status Report', 'Group Cash Balance Report', 
  'Group Weekly Meeting Report', 'Group Payments Report', 'Group Availability Mismatch Report (PMS vs CM)', 'Group Reconciliation Report', 'Group Laundry Report']
  sigleDateSelectReports: Array<string> = ['Night Audit Report', 'Room Status Report', 'Group Cash Balance Report', 'Group Availability Mismatch Report (PMS vs CM)', 'Group Reconciliation Report']
  sigleDateYesDate: Array<string> =['Night Audit Report', 'Group Availability Mismatch Report (PMS vs CM)', 'Group Reconciliation Report']
  paymentTypeReports: Array<string> = ['Group Payments Report', 'Group Expense Report', 'Group Reconciliation Report', 'Payments Report']
  constructor(public dialog: MatDialog, private globals: Globals, private route: ActivatedRoute, private reportsService: ReportsService, private cdRef: ChangeDetectorRef,
    private datePipe: DatePipe, public commS: CommunicationService, private userService: UserService, private fileService: FileService, public storeService: StoreService) {}
  
  /** Default Intilization of Reports Page. */
  ngOnInit(): void {
    this.dataSource = [
      {
        group: 'Front Office',
        key:'frontOffice',
        items: [
          {id: 1, name: 'Arrival Report', key:'arrivalReport'}, 
          {id: 2, name: 'Departure Report', key: 'departureReport'},
          {id: 3, name: 'Room Status Report', key:'roomStatusReport'},
          {id: 4, name: 'Night Audit Report', key:'nightAuditReport'},
          {id: 5, name: 'Police Enquiry Report', key:'policeEnquiryReport'},
          {id: 6, name: 'Management Block Report', key:'managementBlockReport'},
          {id: 7, name: 'Out of Order Room Report', key:'outofOrderRoomReport'},
          {id: 8, name: 'No Show Report', key:'noShowReport'},
          {id: 9, name: 'Reviews Tracking Report', key:'reviewsTrackingReport'},
          {id: 10, name: 'IOT Occupancy Issues Report', key:'iotOccupancyIssuesReport'},
          {id: 11, name: 'Maintenance Report', key:'maintenanceReport'},
          {id: 12, name: 'Laundry Report', key:'laundryReport'}
        ]
      },
      {
        group: 'Accounting',
        key:'accounting',
        items: [
          {id: 11, name: 'Room Wise Revenue Report', key:'roomWiseRevenueReport'},
          {id: 12, name: 'Date Wise Revenue Report', key:'dateWiseRevenueReport'},
          {id: 13, name: 'Checkout based Accounting Report', key:'accountingReport'},
          {id: 14, name: 'Item Consumption Report', key:'itemConsumptionReport'},
          {id: 15, name: 'Payments Report', key:'paymentsReport'},
          {id: 16, name: 'POS Report', key:'posReport'},
          {id: 17, name: 'Payment Void Report', key:'paymentVoidReport'},
          {id: 18, name: 'Expense Report', key:'expenseReport'},
          {id: 19, name: 'City Ledger Payment Report', key:'cityLedgerPaymentReport'},
          {id: 20, name: 'Cancelled Reports', key:'cancelledReports'},
        ]
      },
      {
        group: 'Management',
        key:'management',
        items: [
          {id: 21, name: 'Hotel Sales Report', key:'hotelSalesReport'},
          {id: 22, name: 'Company Performance', key:'companyPerformance'},
          {id: 23, name: 'Monthly Performance Report', key:'monthlyPerformanceReport'},
          {id: 24, name: 'Room Wise Forecast Report', key:'roomWiseForecastReport'},
          {id: 25, name: 'Daily Forecast Report', key:'dailyForecastReport'},
          {id: 26, name: 'Monthly P&L', key:'monthlyP&L'},
          {id: 27, name: 'Contribution Report', key:'contributionReport'},
          {id: 28, name: 'Hotel Weekly Meeting Report', key:'hotelWeeklyMeetingReport'}
        ]
      },
      {
        group: 'Groups',
        key:'groups',
        items: [
        ]
      },
      {
        group: 'Audit',
        key:'audit',
        items: [
          {id: 41, name: 'Same Day Checkouts Report', key:'sameDayCheckoutsReport'},
          {id: 42, name: 'Guest Phone Number Difference', key:'guestPhoneNumberDifference'},
          {id: 43, name: 'Late Checkout Report', key:'latecheckoutReport'},
          {id: 44, name: 'IOT Misconfiguration Report', key:'iotMisconfigurationReport'},
          {id: 45, name: 'Early Checkin Report', key:'earlyCheckinReport'},
          {id: 47, name: 'Room Upgrade Report', key:'roomUpgradeReport'}
        ]
      },
    ];
    this.reportTabName = 'Deleted Reservations';
    this.sortType     = 'roomNo';
    this.sortReverse  = false; 
    this.globals.currentUser.subscribe(user => this.currentUser = user);
    this.route.params.subscribe(params => {
      this.hotelId = params.hotelId;
      this.globals.changeHotelID(this.hotelId);
    });

    this.globals.getCurrencyCode(this.hotelId).subscribe(
      data => {
        this.currencyCode = data;
    });
    this.getChains();
    this.getTallyStatus();
    this.reportName = this.dataSource[0]['items'][0]['name'];
    this.sectionKey = this.dataSource[0]['group'];
    this.onReportsChange();
    this.monthCalc();
    this.getUserFeatures();
  };

  exportAsPdf() {
    this.pdfView = true;
  };


  monthCalc() {
    let fromMonth: any = new Date();
    if(this.reportName == 'Monthly P&L'){
      fromMonth.setMonth(fromMonth.getMonth());
    }
    if(this.reportName == 'Group P&L'){
      fromMonth.setMonth(fromMonth.getMonth() - 1);
    }
    else{
      fromMonth.setFullYear(fromMonth.getFullYear() - 1);
      fromMonth.setMonth(fromMonth.getMonth() + 1);
    }
    fromMonth = fromMonth.toISOString().slice(0, 10).split('-');
    fromMonth = `${fromMonth[0]}-${fromMonth[1]}`;
    this.fromMonth = fromMonth;
    const yearMonth = this.today.split('-');
    this.toMonth = `${yearMonth[0]}-${yearMonth[1]}`;
    this.maxMonth = new Date().toISOString().slice(0, 10).split('-');
    this.maxMonth = `${this.maxMonth[0]}-${this.maxMonth[1]}`;
  }


  /** Get Tally Status based on Hotel Id */
  getTallyStatus(){
    this.userService.getProductConfig(this.hotelId).subscribe(
      data =>{
        this.pmsConfiguration = data.conf;
        this.autoColumnName = this.pmsConfiguration?.specialRequestReplaceText ? this.pmsConfiguration.specialRequestReplaceText:'Special Comments' ;
      });
  };
  

  /** Get hotel chains */
  getChains(){
    this.reportsService.getChains().subscribe(
      chains => {
        this.chainsData = chains; 
        for(var i=0; i<chains.length; i++){
          if(this.hotelId in chains[i].config.hotels){
            this.hotelChains.push(chains[i].name);
          };
        };
        if(this.hotelChains.length > 0){
          for(var i=0; i<this.dataSource.length; i++){
            if(this.dataSource[i]['group'] == 'Groups'){
              this.dataSource[i]['items'].push({id: 31, name: 'Group Sales Report', key:'groupSalesReport', status:true})
              this.dataSource[i]['items'].push({id: 32, name: 'Group P&L', key:'groupP&L', status:true})
              this.dataSource[i]['items'].push({id: 33, name: 'Group Maintenance Report', key:'groupMaintenanceReport', status:true})
              this.dataSource[i]['items'].push({id: 34, name: 'Group Date Wise Revenue Report', key:'groupDateWiseRevenueReport', status:true})
              this.dataSource[i]['items'].push({id: 35, name: 'Group IOT Occupancy Issues Report', key:'groupIotOccupancyIssuesReport', status:true})
              this.dataSource[i]['items'].push({id: 36, name: 'Group IOT Device Monthly Status Report', key:'groupIotDeviceMonthlyStatusReport', status:true})
              this.dataSource[i]['items'].push({id: 37, name: 'Group Cash Balance Report', key:'groupCashBalanceReport', status:true})
              this.dataSource[i]['items'].push({id: 38, name: 'Group Weekly Meeting Report', key:'groupWeeklyMeetingReport', status:true})
              this.dataSource[i]['items'].push({id: 39, name: 'Group Payments Report', key:'groupPaymentsReport', status:true})
              this.dataSource[i]['items'].push({id: 40, name: 'Group Expense Report', key:'groupExpenseReport', status:true})
              this.dataSource[i]['items'].push({id: 41, name: 'Group Availability Mismatch Report (PMS vs CM)', key:'groupAvailabilityMismatchReport', status:true})
              this.dataSource[i]['items'].push({id: 42, name: 'Group Reconciliation Report', key:'groupReconciliationReport', status:true})
              this.dataSource[i]['items'].push({id: 43, name: 'Group Laundry Report', key:'groupLaundryReport', status:true})
            }
          }
        }else{
          this.dataSource.splice(3, 1);
        }
      });
  };
  

  /** Download Reports based on From date and To date */
  downloadFile(fileType) {
    if(this.reportName == 'Night Audit Report'){
      this.toDate = this.fromDate;
    };
    if(this.paymentTypeReports.includes(this.reportName) && !this.selectPaymentType ){
      this.getPaymentTypes();
      if(!this.selectPaymentType){
        this.selectPaymentType = this.paymentTypes[0];
      }
    };
    if(this.companyDetails && this.comapanyName){
      this.selectPaymentType = this.comapanyName;
    }
    this.fileService.downloadFile(this.hotelId, this.currencyCode ,this.fromDate, this.toDate, this.reportName, this.selectChain, this.selectPaymentType, this.selectMealPlan, this.selectUser, this.selectStatus, fileType, this.selectReport, this.selectState, this.selectFloor).subscribe(response => {
      if( fileType == 'tally' && this.reportName == 'Night Audit Report'){
        let blob:any = new Blob([response], { type: 'text/xml; charset=utf-8' });
        let fileName = 'Tally_Interface_'+this.hotelId +'_'+this.toDate
        saveAs(blob, fileName);
      }
      if( fileType == 'pdf' && this.pdfDownloadReports.includes(this.reportName)){
        let blob:any = new Blob([response], { type: 'application/pdf; charset=utf-8' });
        let reportName = this.reportName.replaceAll(' ', '_');
        let fileName =  reportName + '_' +this.hotelId +'_'+ this.toDate
        saveAs(blob, fileName);
      }
      else{
        let blob:any = new Blob([response], { type: 'text/xlsx; charset=utf-8' });
        let fileName = this.reportName.replace(/ /g,"_") + '_' + this.hotelId +'_'+this.toDate +'.xlsx'
        saveAs(blob, fileName);
      }
		}), error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
  };

  showSummary(state){
    if(state){
      this.summaryShow = false;
    }
    if(!state){
      this.summaryShow = true;
    }
  };
  async getPaymentTypes(){
    await this.reportsService.getPaymentTypes(this.hotelId, this.selectChain, this.reportName, this.fromDate, this.toDate).subscribe(
      paymentTypesData=> {
        this.paymentTypes = paymentTypesData.paymentTypes; 
        this.users = paymentTypesData.users;        
      });
  }


  /** Page On change Actions */
  onReportsChange() {
    this.summaryShow = true;    
    if(!this.selectMealPlan){
      this.selectMealPlan = ['ALL']
    };
    if(!this.selectStatus){
      this.selectStatus = 'ALL'
    }
    if(!this.selectState){
      this.selectState = ['ALL']
    };
    if(!this.selectFloor){
      this.selectFloor = 'ALL'
    }
    if (this.reportName === 'Monthly Performance Report' || this.reportName === 'Monthly P&L' ||  this.reportName === 'Group P&L' ) {
      this.fromDate = `${this.fromMonth}-01`;
      let lastDay = new Date(this.toMonth.split('-')[0], this.toMonth.split('-')[1], 0).getDate();
      this.toDate = `${this.toMonth}-` + lastDay;
      if (this.reportName === 'Group P&L' ) {
        let lastDay = new Date(this.fromMonth.split('-')[0], this.fromMonth.split('-')[1], 0).getDate();      
        this.toDate = `${this.fromMonth}-` + lastDay;
      };
    };
    if(!this.selectChain){
      this.selectChain = this.hotelChains[0];
    }
    if(this.paymentTypeReports.includes(this.reportName)){
      this.getPaymentTypes();
    };
    if(!this.selectOptionsType){
      this.selectOptionsType = 'Sent';
    }
    this.reportsService.getReports(this.hotelId, this.fromDate, this.toDate, this.selectChain, this.reportName, this.selectPaymentType, this.selectMealPlan, this.selectUser, this.selectStatus, this.selectOptionsType, this.sectionKey, this.selectState, this.selectFloor).subscribe(
      data => {
        this.reportData = data.report ? data.report:data.policeReport ? data.policeReport: data.paymentsReport; 
        this.hotels = data.hotels ? data.hotels:[];
        if(data.report?.taxNames){
          this.taxLength = data.report.taxNames.length;
        };
        if(data.report?.PaymentNames){
          this.paymentsLength = data.report.PaymentNames.length;
        };
        if(data.report?.allPos){
          this.allPosLength = data.report.allPos.length;
        };
        if(data.report?.months){
          this.monthsLength = data.report.months.length;
        };
        if(data.report?.hotels){
          this.hotelsLength = data.report.hotels.length;
        };
        if(this.reportName == "Contribution Report"){
          this.ContriGrandTotals = data.grandTotals;
        }
        if(data.report?.posNames){
          this.posLength = data.report.posNames.length;
        };
        if(data.report?.segmentNames){
          this.segmentLength = data.report.segmentNames.length;
        };
        if(this.reportData?.mealplanList){
          this.mealplanList = this.reportData.mealplanList;
        };
        if(this.reportData?.statusList){
          this.statusList = this.reportData.statusList;
        };
        if(this.reportData?.stateList){
          this.stateList = this.reportData.stateList;
        };
        if(this.reportData?.floorList){
          this.floorList = this.reportData.floorList;
        };
      }
    );
  };

  sendFeedBackLink(mobileNo, bookingId){
    if(mobileNo){
      this.reportsService.feedbackCall(this.hotelId, mobileNo, bookingId).subscribe(
        res => {
          alert('Message Sent');
          return;
        });
    }else{
      alert('Mobile Number not found');
      return;
    }
  };


  /** Get Invoice/ Folio link based on room allocation Id  */
  getInvoice(roomAllocationId){
    this.userService.getInvoice(this.hotelId, roomAllocationId).subscribe(
      data => {
        data.invoices.forEach(ele => {
          if ('error' in ele){
            const WarningDialog = {title: 'ERROR !', body: `${ele.error}`, cancelBtn: 'Dismiss'};
            this.dialog.open(DialogComponent, {data: WarningDialog});
            return;
          }
          window.open(ele.url, '_blank');
        });
    }, err => {
      const WarningDialog = {title: 'ERROR !', body: `Something went wrong`, cancelBtn: 'Dismiss'};
      this.dialog.open(DialogComponent, {data: WarningDialog});
    });
  };

  onSelect(reportType) {
    this.sectionKey = document.querySelector('select[name="lstparameters"] option:checked').parentElement.getAttribute('label');
    let today = new Date()
    this.groupSelect = false;
    if(this.groupReports.includes(reportType)){
      this.groupSelect = true;
    };
    this.singleDateSelect = false;
    if(this.sigleDateSelectReports.includes(reportType)){
      this.singleDateSelect = true;
    }
    if(reportType != "Night Audit Report"){
      this.toDate = this.datePipe.transform(today, 'yyyy-MM-dd');
      this.fromDate = this.datePipe.transform(today, 'yyyy-MM-dd');
    }
    if(this.sigleDateYesDate.includes(reportType)){
      today.setDate(today.getDate() - 1);
      this.toDate = this.datePipe.transform(today, 'yyyy-MM-dd');
      this.fromDate = this.datePipe.transform(today, 'yyyy-MM-dd');
    }
    if(this.yesTypes.includes(reportType)){
      today.setDate(today.getDate() - 1);
      this.toDate = this.datePipe.transform(today, 'yyyy-MM-dd');
      this.fromDate = this.datePipe.transform(today, 'yyyy-MM-dd');
    }
    if(this.mtdTypes.includes(reportType)){
      this.toDate = this.datePipe.transform(today, 'yyyy-MM-dd');
      this.fromDate = this.datePipe.transform(this.monthFirstDay, 'yyyy-MM-dd');
    }
    if(this.todayTypes.includes(reportType)){
      this.toDate = this.datePipe.transform(today, 'yyyy-MM-dd');
      today.setDate(today.getDate() - 1);
      this.fromDate = this.datePipe.transform(today, 'yyyy-MM-dd');
    }
    if(reportType === 'Room Wise Revenue Report'){
      this.toDate = this.datePipe.transform(today, 'yyyy-MM-dd');
      this.fromDate = this.datePipe.transform(this.tenDaysBeforeDate, 'yyyy-MM-dd');
    }
    if(this.oneWeekTypes.includes(reportType)){
      this.toDate = this.datePipe.transform(today, 'yyyy-MM-dd');
      this.fromDate = this.datePipe.transform(this.oneWeekBeforeDate, 'yyyy-MM-dd');
    }
    if(reportType === 'Date Wise Revenue Report' || reportType === 'Group Date Wise Revenue Report' || reportType === 'Group Weekly Meeting Report' || reportType === 'Hotel Weekly Meeting Report'){
      this.toDate = this.datePipe.transform(this.yestrdayDate, 'yyyy-MM-dd');
      this.fromDate = this.datePipe.transform(this.monthFirstDay, 'yyyy-MM-dd');
      
      if (this.fromDate >this.toDate){
        this.fromDate = this.datePipe.transform(this.lastMonthFirstDay, 'yyyy-MM-dd');
      }
    }
    if (this.reportName == 'Daily Forecast Report' || reportType == 'Room Wise Forecast Report'){
      this.toDate = this.datePipe.transform(this.thirtyDaysAfterDate, 'yyyy-MM-dd');
    }
    if(reportType === 'IOT Misconfiguration Report'){
      this.toDate = this.datePipe.transform(today, 'yyyy-MM-dd');
      this.fromDate = this.datePipe.transform(this.sevenDaysBeforeDate, 'yyyy-MM-dd');
    }
    this.monthCalc()
    this.onReportsChange();
  };

  refreshNightAudit(){
    this.reportsService.refreshNightAuditData(this.hotelId, this.fromDate).subscribe(
      data => {
        alert('Refresh the page after a brief period of time !');
    }, 
    );
  };


  getUserFeatures() {
    const body = {username: localStorage.getItem('user')};
    this.storeService.getUserData(body)
    .subscribe(data => {
      this.userPmsReportFeatures = data?.features.pmsReports;
      for(var i=0; i<this.dataSource.length; i++){
        if(this.userPmsReportFeatures[this.dataSource[i].key]){
          for(var j=0; j<this.dataSource[i].items.length; j++){
            if(this.userPmsReportFeatures [this.dataSource[i].items[j].key]){
              this.dataSource[i].items[j].status = true;
            }
            else{
              this.dataSource[i].items[j].status = false;
            }
          }
        }
        else{
          this.dataSource.splice(i,i);
        }
      };
    });
  };

  sortData(colName, sortReverse){
    if(sortReverse){
      this.reportData.allocationData = this.reportData.allocationData.sort((a,b) => (a[colName] < b[colName]) ? 1 : ((b[colName] < a[colName]) ? -1 : 0))
    }else{
      this.reportData.allocationData = this.reportData.allocationData.sort((a,b) => (a[colName] > b[colName]) ? 1 : ((b[colName] > a[colName]) ? -1 : 0))
    }
  };

  sortData1(colName, sortReverse){
    if(sortReverse){
      this.reportData.data = this.reportData.data.sort((a,b) => (a[colName] < b[colName]) ? 1 : ((b[colName] < a[colName]) ? -1 : 0))
    }else{
      this.reportData.data = this.reportData.data.sort((a,b) => (a[colName] > b[colName]) ? 1 : ((b[colName] > a[colName]) ? -1 : 0))
    }
  };

  getCompanyDetails(name: any, guestData:any){
    this.companyDetails = true;
    this.comapanyName = name;
    this.guestData = guestData;
  }

  backButton(){
    this.companyDetails = false;
    this.comapanyName = false;
    this.selectPaymentType = 'undefined';
  };

  reportTab(name:any){
    this.reportTabName = name;
  };

  bookingdetails(bookingId){
    this.dialog.open(BookingDetailsComponent, {data: {bookingId, hotelId: this.hotelId, currencyCode: this.currencyCode}});
  }

  dateChanged(dateChangedValue){
    if (this.reportName == 'IOT Misconfiguration Report'){
      let fromDate:any = new Date(this.fromDate)
      let toDate:any = new Date(this.toDate)
      if (!isNaN(fromDate.getTime()) && !isNaN(toDate.getTime())) {
        let differenceInTime:number = toDate - fromDate; // Difference in milliseconds
        let differenceInDays:number = differenceInTime / (1000 * 60 * 60 * 24); // Convert to days
        if(dateChangedValue == 'fromDate' && differenceInDays>6){
          this.toDate = new Date(new Date(this.toDate).setDate(new Date(this.fromDate).getDate() + 7)).toISOString().slice(0, 10);
        }else if (dateChangedValue == 'toDate' && differenceInDays>6){
          this.fromDate = new Date(new Date(this.fromDate).setDate(new Date(this.toDate).getDate() - 7)).toISOString().slice(0, 10);
        }
      }
    }
  }
}
