import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { CommunicationService } from 'src/app/core/communication.service';
import { PosService } from 'src/app/core/pos.service';
import { UserService } from 'src/app/core/user.service';
import { AddServiceComponent } from 'src/app/_shared/common_modals/add-service/add-service.component';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { BillComponent } from '../bill/bill.component';
import { TransferOrderComponent } from '../transfer-order/transfer-order.component';
import { Globals } from 'src/app/core/globals.service';
import { DiscountLogComponent } from 'src/app/_shared/common_modals/discount-log/discount-log.component';
import { saveAs } from 'file-saver-es';

@Component({
  selector: 'app-create-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  newOrderForm: FormGroup;
  serviceCategories = {};
  serviceNames = [];
  serviceConfigData: any;
  payments = [];
  servicesUsed = [];
  // services
  category = {};
  serviceName = '';
  totalAmount = 0;
  serviceAmount = 0;
  quantity = 1;
  allTax = {total: 0};
  deleteServicesUsed = [];
  taxInclusive: string;
  isEditable: string;
  discount: number;
  discountType = '%';
  discountApplied = false;
  totalObj = {amount: 0, tax: 0, totalAmount: 0, quantity: 0, discountAmt: 0};
  discountAppliedOn: 'preTax' | 'postTax' = 'preTax';
  features: any;
  gstNum: string = '';

  // JSPM
  printers: string[];
  selectedPrinter: any;
  isDefaultPrinterSelected = false;
  amountChangeTimer: any;
  transferring = false;
  tables = [];
  supportedCurrencies = [];
  selectedCurrency = ''

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public fb: FormBuilder, public dialogRef: MatDialogRef<OrderComponent>,
              private userService: UserService, private posService: PosService, public commS: CommunicationService,
              public dialog: MatDialog,
              public globals: Globals) { }

  ngOnInit(): void {
    this.initializeForm();
    this.categoryObject();

    if (this.data.mode !== 'new') {
      this.servicesUsed = this.data.servicesUsed;
      this.payments = this.data.payments;
      this.gstNum = this.data.currentOrder?.invoice?.gst;
    }

    for (const service of this.servicesUsed) {
      if (service.discounted_amount) {
        this.discountApplied = true;
      }
    }
    
    this.printers = this.data.printers;

    if (this.data.mode === 'edit' && localStorage.getItem('posKotDefaultPrinter')) {
      this.selectedPrinter = localStorage.getItem('posKotDefaultPrinter');
    } else {
      if (localStorage.getItem('posDefaultPrinter')) {
        this.selectedPrinter = localStorage.getItem('posDefaultPrinter');
      }
    }
    if (!this.printers.includes(this.selectedPrinter)) {
      this.selectedPrinter = undefined;
    }

    this.getTables();

    this.totalObj = this.getTotal(this.servicesUsed);

    this.userService.userFeatures$.subscribe(data => {
      this.features = data;
    });

    this.supportedCurrencies = this.userService.supportedCurrencies;
  }

  initializeForm() {
    const posExtra = this.data.currentOrder?.invoice?.posExtraData;
    this.newOrderForm = this.fb.group({
      details: [this.data.details, [Validators.required]],
      services: [null],
      invoiceId: [null],
      deleteServicesUsed: [null],
      user: [this.data.user],
      tableId: [posExtra?.tableId],
      tableNum: [posExtra?.tableNum],
      staff: [posExtra?.staff]
    });
  }

  onSubmit(form) {
    const newServices = this.servicesUsed.filter(ele => {
      return ele.service_used_id === undefined;
    });
    form.controls.services.setValue(newServices);

    if (this.data.mode === 'new') {
      const body = {...form.value, complimentary: this.data.complimentary, gstNum: this.gstNum};
      this.posService.newPosOrder(body, this.data.hotelId, this.data.posName).subscribe(
        data => {
          this.commS.emitPosOrderChange('change');
        });
    } else {
      form.controls.invoiceId.setValue(this.data.invoiceId);
      form.controls.deleteServicesUsed.setValue(this.deleteServicesUsed);
      const body = {...form.value, gstNum: this.gstNum}

      this.posService.editPosOrder(body, this.data.hotelId, this.data.posName).subscribe(
        data => {
          if (data && 'error' in data) {
            this.dialog.open(DialogComponent, {data: {title: 'ERROR', body: data.error, cancelBtn: 'Ok' }});
          }
          this.commS.emitPosOrderChange('change');
        });
    }
  }

  quantityFun(qty: number) {
    const amount = this.taxInclusive === 'No' ? this.serviceConfigData.amount : this.serviceConfigData?.total_amount;
    this.calculateAmount(amount * qty);
  }

  amountChange(amount) {
    this.serviceConfigData.amount = amount / this.quantity;
    clearTimeout(this.amountChangeTimer);

    this.amountChangeTimer = setTimeout(() => {
      this.calculateAmount(amount);
    }, 800);
  }

  // checkbox
  selectAll(value: boolean) {
    this.servicesUsed.forEach(service => {
      if (service.service_used_id) {
        service.checked = value;
      }
    });
  }

  addNewService() {
    if (this.data.complimentary) {
      let duplicate = false;
      for (const item of this.servicesUsed) {
        if (item.service_id === this.serviceConfigData.service_id) {
          duplicate = true;
          item.quantity += this.quantity;
          break;
        }
      }
      if (!duplicate) {
        this.servicesUsed.push({category: this.category, total_amount: 0,
          service_id: this.serviceConfigData.service_id, service_amount: 0, quantity: this.quantity,
          hotelId: this.data.hotelId, service_name: this.serviceName, tax: {total: 0}});
      }
    }
    else if (this.totalAmount > 0 && this.serviceName.length > 0) {
      this.servicesUsed.push({category: this.category, total_amount: this.totalAmount,
      service_id: this.serviceConfigData.service_id, service_amount: this.serviceAmount, quantity: this.quantity,
      hotelId: this.data.hotelId, service_name: this.serviceName, tax: this.allTax});
    }
    this.totalObj = this.getTotal(this.servicesUsed);
  }

  deleteService(index, id, total_amount, service_name) {
    this.servicesUsed.splice(index, 1);
    if (this.data.mode === 'edit' && id) {
      this.deleteServicesUsed.push({id, total_amount, service_name});
    }
    this.totalObj = this.getTotal(this.servicesUsed);
  }

  categoryObject() {
    this.serviceCategories = this.data.serviceCategories;
    this.category = this.serviceCategories[0];
    this.filterServices(this.category);
  }

  filterServices(category) {
    this.userService.getServiceNames(this.data.hotelId, category).subscribe(
      data => {
        this.serviceNames = data;
      }
    );
  }

  getServiceConfig(name) {
    if (name.length > 0) {
      this.userService.getServiceConfig(this.data.hotelId, name, '3').subscribe(
        data => {
          this.serviceConfigData = data;
          this.initAmounts(data);
          this.taxInclusive = data.tax_inclusive;
          this.isEditable = data.editable;
        }
      );
    }
  }

  initAmounts(data) {
    this.totalAmount = data.total_amount;
    this.serviceAmount = data.amount;
    this.allTax = data.tax;
  }

  getTotal(data) {
    const totalObj = {amount: 0, tax: 0, totalAmount: 0, quantity: 0, discountAmt: 0};
    data.forEach(ele => {
      totalObj.amount += ele.service_amount;
      totalObj.tax += ele.tax.total;
      totalObj.totalAmount += ele.total_amount;
      
      totalObj.quantity += ele.quantity;
      if ('discounted_amount' in ele) {
        totalObj.discountAmt += ele.discounted_amount;
      }
    });
    totalObj.amount = Math.round((totalObj.amount + Number.EPSILON) * 100) / 100;
    totalObj.tax = Math.round((totalObj.tax + Number.EPSILON) * 100) / 100;
    totalObj.totalAmount = Math.round((totalObj.totalAmount + Number.EPSILON) * 100) / 100;
    return totalObj;
  }

  calculateAmount(amount) {
    if (amount) {
      const {tax_inclusive, tax_list, perc_total_tax, flat_total_tax} = this.serviceConfigData;
      const body = {amount, taxInclusive: tax_inclusive, taxList: tax_list, percTotalTax: perc_total_tax,
        flatTotalTax: flat_total_tax };
      this.userService.calculateTax(body).subscribe(
      data => {
        this.initAmounts(data);
      });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  applyDiscount() {
    const checkedServices = this.servicesUsed.filter(e => e.checked);
    if (this.discount && checkedServices?.length) {

      const dialogBody = {title: 'Apply Discount', 
      body: `Are you sure you want to apply a discount of ${this.discountType === '%' ? this.discount + '%' : 'flat ' + this.discount}
        on ${this.discountAppliedOn === 'postTax' ? 'Post Tax Amount' : 'Pre Tax Amount'} ?`,
      confirmBtn: 'Yes', cancelBtn: 'Cancel',
      height: '170px'
      };
      const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});

      dialogRef.afterClosed().subscribe(res => {
        if (res === 'true') {
          const body = {
            discount: this.discount, 
            discountType: this.discountType, 
            servicesUsed: checkedServices,
            invoiceId: this.data.invoiceId,
            appliedOn: this.discountAppliedOn
          };
          
          this.userService.applyDiscount(this.data.hotelId, body).subscribe(
            data => {
              this.globals.snackBarFunc('Discount Applied');
              this.getOrder();
            });
        }
      });

    }
  }

  getOrder() {
    this.userService.getInvoiceById(this.data.hotelId, this.data.invoiceId, 'True')
        .subscribe((data) => {
          this.servicesUsed = this.refineServicesUsed(data.services_used.data);
          this.discountApplied = true;
          this.totalObj = this.getTotal(this.servicesUsed);
          console.log(this.totalObj);
          
      })
  }

  refineServicesUsed(servicesUsed) {
    servicesUsed.forEach(e => {
      e.total_amount = parseFloat(e.total_amount);
      const taxObj = {total: 0};
      e.tax.forEach(t => {
        taxObj[t.key] = t.value;
        taxObj.total += parseFloat(t.value)
      })
      e.tax = taxObj
    });
    return servicesUsed;
  }

  getDiscountLog() {
    const servicesUsedIds = this.servicesUsed.map(e => e.service_used_id)
    this.userService.getDiscountLog(this.data.hotelId, null, servicesUsedIds).subscribe(res => {
      if (res?.success && res.data?.length) {
        const dref =this.dialog.open(DiscountLogComponent, {
          width: '1000px',
          data: {
            discountData: res.data,
            hotelId: this.data.hotelId,
          }
        });

        dref.afterClosed().subscribe(res => {
          if (res?.refresh) {
            this.getOrder();
          }
        })
      } else if (res?.success && !res.data?.length) {
        this.globals.snackBarFunc('No Discounts Applied');
      }
    })
  }

  billOrder() {
    const body: any = {};
    body.invoiceId = this.data.invoiceId;
    body.discountApplied = this.discountApplied;
    body.user = localStorage.getItem('user');
    body.complimentary = this.data.complimentary;
    if (this.discountApplied) {
      body.discountType = this.discountType;
      body.discount = this.discount;
      body.servicesUsed = [];
      this.servicesUsed.forEach(ele => {
        body.servicesUsed.push({discounted_amount: ele.discounted_amount, tax: ele.tax, total_amount: ele.total_amount,
          service_used_id: ele.service_used_id});
      });
    }
    this.posService.billOrder(this.data.hotelId, this.data.posName, body).subscribe(
      data => {
        if (data && 'error' in data) {
          this.dialog.open(DialogComponent, {data: {title: 'ERROR', body: data.error, cancelBtn: 'Ok' }});
        }
        this.closeDialog();
        this.commS.emitPosOrderChange('change');
      }
    );
  }

  deletePayment(payment) {
    if (!this.features?.live?.deletePOSPayment) {
      return;
    }
    const body = {amount: payment.amount, user: localStorage.getItem('user')};
    const dialogRef = this.dialog.open(DialogComponent, 
      {data: {
        title: 'Delete Payment', 
        body: 'Are you sure you want to delete this order ?', 
        confirmBtn: 'Yes',
        cancelBtn: 'Cancel' }
      });
    
    dialogRef.afterClosed().subscribe(res => {
      if (res === 'true') {
        this.userService.deletePayment(payment.id, this.data.hotelId, body).subscribe(res => {
          this.payments = this.payments.filter(e => e.id !== payment.id);
          this.commS.emitPosOrderChange('change')
        })
      }
    })
  }

  // JSPM
 changePrinter(printer) {
  this.posService.setPrinter(printer);
 }

 printOrder() {
  if (this.data.mode === 'edit') {
    this.emitKotPrint();
  } else if (this.data.config?.format === 'A4') {
    const data = {order: this.data.currentOrder, config: this.data.config};
    this.getA4Invoice(data);
  } else {
    this.emitEscPosPrint();
  }
  this.closeDialog();
 }

 emitEscPosPrint() {
   this.posService.emitPrintEvent(this.data.mode);
 }

 emitKotPrint() {
  this.posService.emitKotPrint();
 }
  
 emitViewBill() {
  this.data.config.currencyCode = localStorage.getItem('currencyCode')
  const data = {order: this.data.currentOrder, config: this.data.config};
  if (this.data.config?.format === 'A4') {
    this.getA4Invoice(data);
  } else {
    this.dialog.open(BillComponent, {data});
  }

 }

 getA4Invoice(data) {
  this.posService.posA4Invoice(data, this.data.hotelId).subscribe(res => {
    window.open(res.url, '_blank');
  })
 }

 getCurrencyInvoice() {
  const data = {order: this.data.currentOrder, config: this.data.config};
  this.posService.downloadCurrencyPosA4Invoice(data, this.data.hotelId, this.selectedCurrency).subscribe(res => {
    const blob: any = new Blob([res], {type: 'text/pdf; charset=utf-8'});
    const fileName = `${this.selectedCurrency}-${this.data.invoiceId}.pdf`;
    saveAs(blob, fileName);
  })
 }

 openAddService() {
   const dRef = this.dialog.open(AddServiceComponent, {
     width: '600px',
     maxWidth: '95%',
     data: {hotelId: this.data.hotelId}
   });

   dRef.afterClosed().subscribe(data => {
     if (data?.data) {
      this.filterServices(data.data?.category);
     }
   });
 }

 transferServicesClick(newOrder = false) {
  const services = [];
  this.servicesUsed.forEach(e => {
    if (e.checked) {
      services.push(JSON.parse(JSON.stringify(e)));
    }
  });

  if (!services.length) {
    this.dialog.open(DialogComponent, {
      width: '400px',
      data:  {
        title: 'Oops !', body: `Please select a service to transfer`, confirmBtn: 'Ok'
      }
    });
    return;
  }

  if (newOrder) {
    this.transferServicesNew(services);
  } else {
    this.openTransferOrder(services);
  }
 }

 transferServicesNew(services: any[]) {
  const serviceUsedIds: string[] = services.map(e => e.service_used_id);
  const body = {
    serviceUsedIds,
    transferToInvoice: null,
    transferFromInvoice: this.data.invoiceId
  };
  this.transferServices(body);
 }

 openTransferOrder(services: any[]) {
  const dRef = this.dialog.open(TransferOrderComponent, {
    width: '600px',
    maxWidth: '95%',
    data: {
      services,
      currentInvoiceId: this.data.invoiceId,
      hotelId: this.data.hotelId,
      posName: this.data.posName
    }
  });

  dRef.afterClosed().subscribe(res => {
    if (res.success) {
      this.transferServices(res.body);
    }
  });
 }

 transferServices(body) {
  this.transferring = true;
  this.posService.transferService(this.data.hotelId, this.data.posName, body)
    .pipe(
      finalize(() => this.transferring = false)
    )
    .subscribe(data => {
      if (data?.success) {
        this.commS.emitPosOrderChange('change');
        this.closeDialog();
        this.dialog.open(DialogComponent, {
          width: '400px',
          data: {title: 'Success', body: data.successMsg, confirmBtn: 'Ok'}
        });
      }
  });
 }

 getTables() {
  this.posService.getTables(this.data.posName, this.data.hotelId).subscribe(res => {
    if (res?.success) {
      this.tables = res?.data;
    }   
  })
 }

 setTable(tableId) {
  const tableNum = this.tables.filter(e => e.id === tableId)[0].tableNum;
  this.newOrderForm.get('tableNum').setValue(tableNum);
 }


 openLink(link) {
  window.open(link, '_blank');
 }

 editGst() {
  this.posService.editGst(this.data.hotelId, this.data.invoiceId, this.gstNum).subscribe(res => {
    this.globals.snackBarFunc('GST No. updated !')
  })
 }

 currencyBill() {
  if (!(this.supportedCurrencies.includes(this.selectedCurrency))) {
    return;
  }
  this.data.config.currencyCode = this.selectedCurrency;
  if (this.data.config?.format === 'A4') {
    this.getCurrencyInvoice();
  } else {
    this.userService.invoiceCurrencyBasedParams(this.data.hotelId, this.data.invoiceId, this.selectedCurrency).subscribe(res => {
      const data = {order: res, config: this.data.config};
      this.dialog.open(BillComponent, {data});
    })
  }
 }

}
