import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class FileService {

  constructor(private http: HttpClient) {}

  downloadFile(hotelId, currency, start, end, typo, chainId, paymentType, mealPlan, user, status,fileType, option, state, floor): any {
    let params = new HttpParams();
    params = params.append('start', start);
    params = params.append('end', end);
    params = params.append('currency', currency);
    params = params.append('chainId', chainId);
    params = params.append('paymentType', paymentType);
    params = params.append('mealPlan', mealPlan);
    params = params.append('user', user);
    params = params.append('fileType', fileType);
    params = params.append('status', status);
    params = params.append('option', option);
    params = params.append('state', state);
    params = params.append('floor', floor);
    return this.http.get(`${environment.apiUrl}/pms/download/${typo}/${hotelId}`, {params: params, responseType: 'blob'});
  }
}