import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { PrintService } from './print.service';

interface TransferSerivceBody {
  serviceUsedIds: string[];
  transferToInvoice: string | null;
  transferFromInvoice: string;
  newServices?: any[];
}

@Injectable({
  providedIn: 'root'
})
export class PosService {
  private printer = new BehaviorSubject<string>('none');
  selectedPrinter = this.printer.asObservable();
  print: EventEmitter<any> = new EventEmitter();
  
  kotPrint: EventEmitter<any> = new EventEmitter();
  private _currentPosConfig: any;

  private currentOrdersV = [];
  getBalance: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private printService: PrintService
  ) { }

  // HTTP Services

  newPosOrder(body, hotelId, posName): Observable<any> {
    return this.http.post(`${environment.apiUrl}/pos/${hotelId}/${posName}`, body);
  }

  editPosOrder(body, hotelId, posName): Observable<any> {
    return this.http.put(`${environment.apiUrl}/pos/${hotelId}/${posName}`, body);
  }

  getPosOrders(hotelId, posName, startDate, endDate, today): Observable<any> {
    const httpParams = new HttpParams(
      {fromObject: {startDate, endDate, today}});
    return this.http.get(`${environment.apiUrl}/pos/${hotelId}/${posName}`, {params: httpParams});
  }

  billOrder(hotelId, posName, body): Observable<any> {
    return this.http.put(`${environment.apiUrl}/pos/${hotelId}/${posName}/bill`, body);
  }

  deletePosOrder(hotelId, posName, invoiceId, user, orderId): Observable<any> {
    const httpParams = new HttpParams(
      {fromObject: {invoiceId, user, orderId}});
    return this.http.delete(`${environment.apiUrl}/pos/${hotelId}/${posName}`, {params: httpParams});
  }

  transferService(hotelId: string, posName: string, body: TransferSerivceBody): Observable<any> {
    return this.http.put(`${environment.apiUrl}/pos/${hotelId}/${posName}/transfer-service`, body);
  }

  applyDiscount(hotelId, body): Observable<any> {
    return this.http.post(`${environment.apiUrl}/services-used/apply-discount/${hotelId}`, body);
  }

  orderReadyStatusToggle(invoiceId, status, hotelId): Observable<any> {
    return this.http.put(`${environment.apiUrl}/pos/${hotelId}/order-ready`, {status, invoiceId});
  }

  setPrinter(printer: string) {
    localStorage.setItem('posDefaultPrinter', printer)
    this.printer.next(printer);
  }

  fetchPosLogs(hotelId, posName, fromDate, toDate): Observable<any> {
    const httpParams = new HttpParams(
      {fromObject: {fromDate, toDate}});
    return this.http.get(`${environment.apiUrl}/pos-logs/${hotelId}/${posName}`, {params: httpParams});
  }

  getTables(posName, hotelId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/pos/${hotelId}/${posName}/table-number`);
  }

  getPosConfig(hotelId): Observable <any> {
    return this.http.get(`${environment.apiUrl}/hotels/${hotelId}/products/pos`);
  };

  getPmsPosReports(hotelId, reportName, posName, startDate, endDate): Observable <any> {
    const httpParams = new HttpParams(
      {fromObject: {reportName, posName,startDate, endDate}});
    return this.http.get(`${environment.apiUrl}/pms-pos-reports/${hotelId}`, {params: httpParams});
  }

  undoBill(hotelId, invoiceId): Observable<any> {
    return this.http.put(`${environment.apiUrl}/pos/${hotelId}/${invoiceId}/undo-bill`, {});
  }

  editGst(hotelId, invoiceId, gstNum): Observable<any> {
    return this.http.put(`${environment.apiUrl}/pos/${hotelId}/edit-gst`, {invoiceId, gstNum});
  }

  posA4Invoice(data, hotelId): Observable<any> {
    return this.http.post(`${environment.apiUrl}/pos/${hotelId}/a4-invoice`, data);
  }

  downloadCurrencyPosA4Invoice(data, hotelId, currency): Observable<any> {
    return this.http.post(`${environment.apiUrl}/pos/${hotelId}/a4-invoice/${currency}`, data, {responseType: 'blob'});
  }

  // Functions

  get currentPosConfig() {
    return this._currentPosConfig;
  }

  set currentPosConfig(data) {
    this._currentPosConfig = data;
  }

  set currentOrders(value: any[]) {
    this.currentOrdersV = value;
  }

  get currentOrders() {
    return this.currentOrdersV;
  }

  emitPrintEvent(mode: string) {
    this.print.emit(mode);
  }

  emitKotPrint(order?) {
    this.kotPrint.emit(order)
  }

  escPosPrint(mode, currentOrder, posConfig) {
    const data = currentOrder;
    const conf = posConfig;
    const width = conf.format === 'Thermal wide' ? 40 : 30;
    const stLine = '-'.repeat(width);
    let dateTime = data.invoice.billed_date ? data.invoice.billed_date : data.invoice.date;
    dateTime = this.datePipe.transform(dateTime, 'dd MMM h:mm a');

    const alignCenter = (length) => {
      const alignValue = (width - length) / 2;
      return alignValue > 0 ? alignValue : 1;
    };
    const getSpace = (length) => width - length;

    const esc = '\x1B'; // ESC byte in hex notation
    const gs = '\x1D';
    const newLine = '\x0A'; // LF byte in hex notation
    const cutPaper = '\x1D\x56\x01';

    let cmds = esc + '@'; // Initializes the printer (ESC @)
    cmds += esc + '!' + '\x18'; // Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
    if ('invoiceHeading' in conf) {
      const heading = conf.invoiceHeading.split('<break>');
      heading.forEach((ele) => {
        cmds += ' '.repeat(alignCenter(ele?.length)) + ele;
        cmds += newLine;
      });
    }
    cmds += esc + '!' + '\x00';
    if ('invoiceAddress' in conf) {
      const subHeading = conf.invoiceAddress.split('<break>');
      subHeading.forEach((ele) => {
        cmds += ' '.repeat(alignCenter(ele?.length)) + ele;
        cmds += newLine;
      });
    }
    cmds += newLine + stLine + newLine;
    
    const billOrOrderLen = data.invoice.num ? data.invoice.num.length : data.invoice.order_id.length + 1;
    let space = getSpace(9 + billOrOrderLen + data.invoice.details?.length);
    space = space >= 0 ? space : 0;

    if (data.invoice.num) {
      cmds += 'Bill No: ' + data.invoice.num + ' '.repeat(space) + data.invoice.details;
    } else {
      cmds += 'Order No: ' + data.invoice.order_id + ' '.repeat(space) + data.invoice.details;
    }
    cmds += newLine;
    cmds += 'Date: ' + dateTime;
    cmds += newLine + stLine + newLine;

    // ================ HEADING ================//
    const woRateHeading = 'Service         ' + ' Qty ' + '      Amt';
    const wRateHeading =
      'Service          ' + ' Qty ' + '    Rate ' + '   Amount';
    if (mode !== 'edit') {
      cmds += conf.format === 'Thermal wide' ? wRateHeading : woRateHeading;
    } else if (mode === 'edit') {
      cmds += 'Service                    ' + ' Qty ';
    }

    cmds += newLine + stLine + newLine;

    let totalRate = 0;
    let slNo = 1;

    // ================ SERVICES USED ================//
    data.services_used.data.forEach((item) => {
      let name = item.service_name;
      if (mode !== 'edit' && name?.length > 15) {
        name = name.slice(0, 15);
      } else if (mode === 'edit' && name?.length > 25) {
        name = name.slice(0, 25);
      }
      let space1 = conf.format === 'Thermal wide' ? 17 - name?.length : 16 - name?.length;
      space1 = space1 > 0 ? space1 : space1 * -1;
      const space2 = conf.format === 'Thermal wide' ? 10 - item.amount.toString().length : 11 - item.amount.toString().length;
      totalRate += parseFloat(item.rate);
      const rateSpace = 10 - item.rate?.length;

      const woRateItem = slNo +  '.' + name + ' '.repeat(space1) + item.quantity + ' '.repeat(space2) + item.amount;
      const wRateItem = slNo + '.' + name + ' '.repeat(space1) + item.quantity + ' '.repeat(rateSpace) + item.rate + ' '.repeat(space2) + item.amount;
      if (mode !== 'edit') {
        cmds += conf.format === 'Thermal wide' ? wRateItem : woRateItem;
      } else if (mode === 'edit') {
        cmds += slNo + '.' + name + ' '.repeat(27 - name?.length) + item.quantity + ' '.repeat(20 - item.amount.toString().length);
      }
      cmds += newLine;
      slNo++;
    });

    cmds += stLine + newLine;

    // ================ TOTAL SECTION ================//
    if (mode !== 'edit') {
      const totalAmt = data.services_used.amount_sum.toString();
      const totalQty = data.services_used.total_quantity;
      const discount = data.services_used.total_discount.toString();
      const space3 = conf.format === 'Thermal wide' ? 21 - discount?.length: 12 - discount?.length;
      const space4 = conf.format === 'Thermal wide' ? 20 - totalAmt?.length : 11 - totalAmt?.length;
      const tillChar = conf.format === 'Thermal wide' ? 19 : 18;
      const woRateTotal = ' '.repeat(tillChar - 6) + 'Total ' + totalQty + ' '.repeat(space4) + totalAmt;
      const totalRateSpace = 10 - totalRate.toFixed(2)?.length;
      const wRateTotal = ' '.repeat(tillChar - 6) + 'Total ' + totalQty + ' '.repeat(space4) + totalAmt;
      cmds += conf.format === 'Thermal wide' ? wRateTotal : woRateTotal;
      cmds += newLine;
      cmds += ' '.repeat(tillChar - 9) + 'Discount ' + ' '.repeat(space3) + discount;
      cmds += newLine;
      data.tax.totalTaxBreakup.forEach((tax) => {
        const space5 = conf.format === 'Thermal wide'
            ? 22 - tax.value.toString()?.length
            : 13 - tax.value.toString()?.length;
        cmds += ' '.repeat(tillChar - tax.key?.length - 1) + tax.key + ' '.repeat(space5) + tax.value;
        cmds += newLine;
      });
      const space6 = conf.format === 'Thermal wide' ? 21 - data.invoice.total.toString().length : 12 - data.invoice.total.toString().length;
      cmds += ' '.repeat(tillChar - 11) + 'NET AMOUNT ' + ' '.repeat(space6) + data.invoice.total;
    }
    cmds += newLine + newLine + newLine;

    if ('invoiceFooter' in conf && mode !== 'edit') {
      cmds += ' '.repeat(alignCenter(conf.invoiceFooter?.length)) + conf.invoiceFooter;
    }
    cmds += newLine + newLine + newLine + newLine;
    cmds += cutPaper;

    if (mode === 'edit') {
      const noOfKotPrint = conf.noOfKotPrint ? parseInt(conf.noOfKotPrint) : 1;
      for(let i=0; i<=noOfKotPrint; i++) {
        console.log(cmds);
        this.printService.print(cmds)
      }
    } 
    else {
      console.log(cmds);
      this.printService.print(cmds)
    }

  }
}
