import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { debounceTime } from 'rxjs/operators';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { CommunicationService } from 'src/app/core/communication.service';
import { ReservationService } from 'src/app/core/reservation.service';

@Component({
  selector: 'app-quick-reservation',
  templateUrl: './quick-reservation.component.html',
  styleUrls: ['./quick-reservation.component.scss']
})
export class QuickReservationComponent implements OnInit {

  quickForm: FormGroup;
  infoData: any = {};
  roomTypeInfo: any = {};
  submitting = false;
  sendPaymentLink = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<QuickReservationComponent>,
    private fb: FormBuilder,
    private reserService: ReservationService,
    private commsService: CommunicationService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getRequiredInfo(this.data.reservation.checkin, this.data.reservation.checkout);
  }

  initForm() {
    const reservation = this.data.reservation;
    
    this.quickForm = this.fb.group({
      checkin: [reservation.checkin, Validators.required],
      checkout: [reservation.checkout, Validators.required],
      pax: ['2', Validators.required],
      mealplan: ['', Validators.required],
      guestName: ['', Validators.required],
      email: [''],
      phone: [''],
      perDayRate: ['', Validators.required],
      perDayTax: ['', Validators.required],
      total: ['', Validators.required],
      taxInclusive: [{value: false, disabled: !this.data?.features?.addRate }, Validators.required],
      paymentMode: ['', Validators.required]
    });

    this.subscribeToChange();
  }

  subscribeToChange() {
    this.quickForm.get('checkout')?.valueChanges.subscribe(v => {
      const formData = this.quickForm.value;
      this.getRequiredInfo(formData.checkin, v);
    });

    const controls1 = ['pax', 'mealplan'];
    controls1.forEach(e => {
      this.quickForm.get(e)?.valueChanges.subscribe(v => {
        this.ratePlanSelect();
      })
    });

    this.quickForm.get('perDayRate').valueChanges
    .pipe(debounceTime(500))
    .subscribe(pd => {
      this.editRate(pd);
    });

    this.quickForm.get('taxInclusive')?.valueChanges.subscribe(v => {
      this.ratePlanSelect();
    });

  }

  getRequiredInfo(checkin, checkout) {
    this.reserService.getRequiredInfo(this.data.hotelId, checkin, checkout).subscribe(res => {
      this.infoData = res;
      this.infoData.roomtypesInfo?.forEach(e => {
        if (e.roomId === this.data.reservation.room.roomTypeId) {
          this.roomTypeInfo = e;
          if (this.roomTypeInfo.availableRooms <= 0) {
            const dialogBody =
            {
              title: 'NO ROOMS AVAILABLE',
              body: `No rooms available for selected date`,
              cancelBtn: 'Ok'
            };
            this.dialog.open(DialogComponent, {
              data: dialogBody
            });
            this.dialogRef.close();
          }
          
          if (this.roomTypeInfo.mealplans.length) {
            this.quickForm.get('mealplan').setValue(this.roomTypeInfo.mealplans[0]);
          }
          if (Math.max(...this.roomTypeInfo.numGuestsAllowed) < 2) {
            this.quickForm.get('pax').setValue('1');
          }
        }
      });
      if (this.infoData?.paymentModes?.length) {
        this.quickForm.get('paymentMode').setValue(this.infoData.paymentModes[0]);
      }
      if (this.infoData?.taxInclusive) {
        this.quickForm.get('taxInclusive').setValue(this.infoData?.taxInclusive);
        this.quickForm.get('taxInclusive').disable();
      }
    })
  }

  submit() {
    if (this.quickForm.invalid || !this.data?.features?.createReservation || this.submitting) {
      return;
    }

    const formData = this.quickForm.getRawValue();
    const nights = this.daysBetween(formData.checkin, formData.checkout);
    
    const payload = {
      "checkin": formData.checkin,
      "checkout": formData.checkout,
      "nights": nights.toString(),
      "rooms": 1,
      "numguest": formData.pax,
      "roomtype": this.data.reservation.room.roomTypeName,
      "rateplan": formData.mealplan,
      "business": "Walk-in",
      "billto": formData.guestName,
      "PaymentMode": formData.paymentMode,
      "currencycode": this.infoData.currencyCode,
      "amount": formData.perDayRate * nights,
      "taxinclusive": formData.taxInclusive,
      "tax": formData.perDayTax * nights,
      "room_num": this.data.reservation.room.roomNum,
      "name": formData.guestName,
      "email": formData.email,
      "phone": formData.phone,
      "idproof": null,
      "dob": "",
      "gender": null,
      "inputAddress": null,
      "city": null,
      "state": null,
      "zipcode": null,
      "country": null,
      "special_request": null,
      "id_detail": null,
      "bookedby": null,
      "extraChild": "0",
      "prePaymentMode": null,
      "prePaidAmount": 0,
      "billtoForPrePaid": null,
      "commentsPrepaid": null,
      "editedChannel": "PMS",
      "expirationDate": "",
      "purposeOfVisit": null,
      "comingFrom": null,
      "goingTo": null,
      "vipGuest": false,
      "internalComments": null,
      "discountType": "percentage",
      "discountValue": 0,
      "roomidselected": this.data.reservation.room.roomTypeId,
      "company_details": [],
      "files": [],
      "hotelId": this.data.hotelId,
      "room_num_id": this.data.reservation.room.roomNumId,
      "rateplan_id": this.roomTypeInfo.selectedRatePlan,
      "user": localStorage.getItem('user'),
      "action": "book",
      "sendPaymentLink": this.sendPaymentLink
    }

    this.submitting = true;
    this.reserService.createReservation(payload).subscribe(res => {
      this.commsService.emitStayViewReload();
      this.submitting = false;
      this.dialogRef.close();
    })
  }

  daysBetween(date1, date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const timeDifference = Math.abs(d2.getTime() - d1.getTime());
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  }

  ratePlanSelect() {
    const formData = this.quickForm.value;
    const nights = this.daysBetween(formData.checkin, formData.checkout) || 1; // min 1

    this.roomTypeInfo?.rateplans?.forEach(e => {
      if (e.mealplan === this.quickForm.get('mealplan').value && 
          e.numOfGuest == this.quickForm.get('pax').value) {
      
        this.roomTypeInfo.selectedRatePlan = e.rateplanId;
        const perDayRate = this.infoData.taxInclusive ? (e.perDayPretaxRate + e.perDayTax) : e.perDayPretaxRate;
        this.quickForm.get('perDayRate').setValue(perDayRate, { emitEvent: false });
        this.quickForm.get('perDayTax').setValue(e.perDayTax);
        let total = perDayRate;
        if (!this.quickForm.get('taxInclusive').value) {
          total += e.perDayTax;
        }
        total *= nights;
        this.quickForm.get('total').setValue(total);
      }
    });
  }

  editRate(amount) {
    if (this.quickForm.get('taxInclusive').value) {
      this.quickForm.get('total').setValue(amount);
      return;
    }
    this.reserService.fetchtax(this.data.hotelId, amount).subscribe(res => {
      this.roomTypeInfo?.rateplans?.forEach(e => {
        if (e.mealplan === this.quickForm.get('mealplan').value && 
            e.numOfGuest == this.quickForm.get('pax').value) {
          e.perDayPretaxRate = res?.pretax_amount;
          e.perDayTax = res?.rounded_tax;
          this.ratePlanSelect();
        }
      });
    })
  }

  numberOnly(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (
      // Only allow one decimal point
      (charCode === 46 && (event.target as HTMLInputElement).value.includes('.')) || 
      // Disallow non-numeric characters
      (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57))
    ) {
      event.preventDefault();
    }
  }

}
