import { Component, Inject, OnInit } from '@angular/core';
import { Globals } from 'src/app/core/globals.service';
import { StoreService } from 'src/app/core/store.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-payment-links',
  templateUrl: './payment-links.component.html',
  styleUrls: ['./payment-links.component.scss']
})
export class PaymentLinksComponent implements OnInit {

  iframeUrl: string;
  hotelId: string;
  invoiceId: string = '';
  amount: string;
  hotelName: string = '';
  

  constructor(
    public globals: Globals,
    private storeService: StoreService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.hotelId = this.data.hotelId;
    this.getHotel();
  }

  getHotel() {
    this.storeService.getHotel(this.hotelId).subscribe(res => {
      this.hotelName = res?.globals?.name;
    })
  }

  updateUrl() {
    this.iframeUrl = `https://live.aiopay.in/payment/pay?client_id=aiosell-pms&property_id=${this.hotelId}&receiver_name=${encodeURIComponent(this.hotelName)}`;
    if (this.amount) {
      this.iframeUrl += `&amount=${this.amount}`;
    }
    if (this.invoiceId) {
      this.iframeUrl += `&invoice_id=${this.invoiceId}`;
    }

    // Open the URL in a new tab
    window.open(this.iframeUrl, '_blank');
  }

  closeDialog(){
    this.dialog.closeAll();
  }
}
