import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompaniesService } from 'src/app/core/companies.service';

@Component({
  selector: 'app-ageing-ledger',
  templateUrl: './ageing-ledger.component.html',
  styleUrls: ['./ageing-ledger.component.scss']
})
export class AgeingLedgerComponent implements OnInit {
  hotelId: string;
  ageingReportData = []

  constructor(
    private companiesService: CompaniesService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.hotelId = params.hotelId;
      this.getAgeingReport();
    });
  }

  getAgeingReport() {
    this.companiesService.getAgeingReport(this.hotelId).subscribe(res => {
      this.ageingReportData = res?.data;
    })
  }

}
