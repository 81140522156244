<ng-container>
    <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <h2 *ngIf="showPaymentStatus" >Payment Status</h2>
            <h2 *ngIf="showSettlementReport || showSelectedDateSettlementReport" >Settlement Report</h2>
        </div>
        <div class="navbar-end">
            <div class="navbar-item">
              <button *ngIf="showPaymentStatus" mat-raised-button class="primary_btn" (click)="sendPaymentLinksAuto()">
                Send Payment Links Automatically
              </button>
              <button *ngIf="showPaymentStatus" mat-raised-button class="primary_btn" (click)="showBankDetails()">
                Bank Details
              </button>
              <button *ngIf="showPaymentStatus" mat-raised-button class="primary_btn" (click)="createPaymentLink()">
                Create Payment Link
              </button>
              <button *ngIf="showPaymentStatus" mat-raised-button class="primary_btn" (click)="showFullSettlementReport()">
                Settlement Report
              </button>
              <button *ngIf="showSettlementReport" style="display: inline-block" class="btn" (click)="showPaymentStatusPage()">
                  Back
              </button>
              <button *ngIf="showSelectedDateSettlementReport" style="display: inline-block" class="btn" (click)="showFullSettlementReport()">
                Back
              </button>
            </div>
        </div>
    </nav>
    <hr>
</ng-container>

<div>
    <div class="d-flex">
        <div class="form-row" *ngIf="showPaymentStatus">
          <div class="form-group col-lg-5">
            <div class="form-row">
              <div class="form-group col-sm-3">
                <label for="fromDate">From Date:</label>
                <input type="date" class="form-control form-control-sm" onkeydown="return false" id="fromDate" [(ngModel)]='fromDate' name="FromDate" >
              </div>
              <div class="form-group col-sm-3">
                <label for="toDate">To Date:</label>
                <input type="date" class="form-control form-control-sm" onkeydown="return false" id="toDate" [(ngModel)]='toDate' name="ToDate" >
              </div>
              <div class="form-group col-sm-3">
                <label for="transactId">Transaction ID:</label>
                <input type="text" class="form-control form-control-sm" id="transactId" [(ngModel)]='transactId' name="transactId" >
              </div>
              <div class="form-group col-sm-3">
                <label for="bookingId">Booking ID:</label>
                <input type="text" class="form-control form-control-sm" id="bookingId" [(ngModel)]='bookingId' name="bookingId" >
              </div>
            </div>
          </div>
          <div class="form-group col-lg-7">
            <div class="form-row">
              <div class="form-group col-sm-3">
                <label for="name">Guest Name:</label>
                <input type="text" class="form-control form-control-sm" id="name" [(ngModel)]='name' name="name" >
              </div>
              <div class="form-group col-sm-3">
                <label for="orderStatus">Order Status:</label>
                <select class="form-control form-control-sm" id="orderStatus" [(ngModel)]="orderStatus" name="orderStatus">
                  <option value = "paid">Paid</option>
                  <option value = "processing" >Processing</option>
                  <option value = "order_created" >Order Created</option>
                  <option value = "refund_processed" >Refund Processed</option>
                  <option value = "failed" >Failed</option>
                </select>
              </div>
              <div class="form-group col-sm-4">
                <label for="paymentLink">Payment Link:</label>
                <input type="text" class="form-control form-control-sm" id="paymentLink" [(ngModel)]='paymentLink' name="paymentLink" >
              </div>
              <div class="col-sm-1" id="submit" >
                  <button type="button" class="btn submit form-control-sm" (click)="fetchPaymentStatus(fromDate, toDate)">Submit</button>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row" *ngIf="showSettlementReport">
            <div class="form-group col-md-4 col-sm-4">
                <label for="fromDate">From Date:</label>
                <input type="date" class="form-control form-control-sm" onkeydown="return false" id="fromDate" [(ngModel)]='fromDate' name="FromDate" >
            </div>
            <div class="form-group col-md-4 col-sm-4">
                <label for="toDate">To Date:</label>
                <input type="date" class="form-control form-control-sm" onkeydown="return false" id="toDate" [(ngModel)]='toDate' name="ToDate" >
            </div>
            <div class="col-sm-2" id="submit" >
                <button type="button" class="btn submit form-control-sm" (click)="fetchSettlements(fromDate, toDate)">Submit</button>
            </div>
        </div>
    </div>
    <br>
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort *ngIf="showPaymentStatus || showSelectedDateSettlementReport">
            <!-- Booking ID Column -->
            <ng-container matColumnDef="booking_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Booking ID </th>
              <td mat-cell class="left" *matCellDef="let element"><a data-toggle="modal" data-target="#myModal" style="color: #3c8dbc;cursor: pointer;" data-backdrop="false" (click)="bookingdetails(element.booking_id)"> {{element.booking_id}} </a></td>
            </ng-container>
        
            <!-- First Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell class="left" *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- Last Name Column -->
            <ng-container matColumnDef="product">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Product </th>
                <td mat-cell class="left" *matCellDef="let element"> {{element.product}} </td>
              </ng-container>

            <!-- Transaction ID Column -->
            <ng-container matColumnDef="transaction_id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction ID</th>
                <td mat-cell class="left" *matCellDef="let element"> {{element.transaction_id}} </td>
            </ng-container>
        
            <!-- Transaction Date Column -->
            <ng-container matColumnDef="transaction_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Transaction Date</th>
              <td mat-cell class="left" *matCellDef="let element"> {{element.transaction_date | date:'MMM d yyyy h:mm a'}} </td>
            </ng-container>
        
            <!-- checkin_date Column -->
            <ng-container matColumnDef="checkin">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Checkin </th>
              <td mat-cell class="left" *matCellDef="let element"> {{element.checkin | date:'MMM d'}} </td>
            </ng-container>
      
            <!-- checkout_date Column -->
            <ng-container matColumnDef="checkout">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Checkout </th>
              <td mat-cell class="left" *matCellDef="let element"> {{element.checkout | date:'MMM d'}} </td>
            </ng-container>
      
            <!-- Amount Column -->
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
              <td mat-cell id="middle" *matCellDef="let element"> {{element.amount}} </td>
            </ng-container>
      
            <!-- PG Charges Column -->
            <ng-container matColumnDef="pg_charges">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> PG Charges </th>
              <td mat-cell id="middle1" *matCellDef="let element"> {{element.pg_charges}} </td>
            </ng-container>      
      
            <!-- TAX Column -->
            <ng-container matColumnDef="tax">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tax </th>
              <td mat-cell id="middle2" *matCellDef="let element"> {{element.tax}} </td>
            </ng-container>
      
            <!-- Net Amount Column -->
            <ng-container matColumnDef="net_amount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Net Amount </th>
              <td mat-cell id="middle3" *matCellDef="let element"> {{element.net_amount}} </td>
            </ng-container>

            <!-- Net Amount Column -->
            <ng-container matColumnDef="confirmation_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Confirmation ID </th>
              <td mat-cell id="middle3" *matCellDef="let element"> {{element.confirmation_id}} </td>
            </ng-container>

            <!-- Net Amount Column -->
            <ng-container matColumnDef="payment_link">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Payment Link </th>
              <td mat-cell id="middle3" *matCellDef="let element"> <a *ngIf = "element.payment_link" href="{{element.payment_link}}" target="_blank" rel="noopener noreferrer">View</a></td>
            </ng-container>

            <!-- Net Amount Column -->
            <ng-container matColumnDef="settlement_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Settlement Date </th>
              <td mat-cell id="middle3" *matCellDef="let element"> {{element.settlement_date | date:'MMM d yyyy'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <table mat-table [dataSource]="dataSource" matSort *ngIf="showSettlementReport">
            <!-- Date Of Settlement Column -->
            <ng-container matColumnDef="date_of_settlement">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Of Settlement </th>
              <td mat-cell id="middle1" *matCellDef="let element"> {{element.date_of_settlement  | date:'MMM d yyyy'}} </td>
            </ng-container>      
      
            <!-- No Of Transactions Column -->
            <ng-container matColumnDef="no_of_transactions">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> No Of Transactions </th>
              <td mat-cell id="middle2" *matCellDef="let element"> {{element.no_of_transactions}} </td>
            </ng-container>
      
            <!-- Amount Transferred Column -->
            <ng-container matColumnDef="amt_transferred">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount Transferred </th>
              <td mat-cell id="middle3" *matCellDef="let element"><a style="color: #3c8dbc;cursor: pointer;" (click)="fetchSettlementDataOnSelectedDate(element.date_of_settlement)">{{element.amt_transferred}}</a>  </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[20, 30, 40, 50]" showFirstLastButtons></mat-paginator>
    </div>
</div>

