
<nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    {{'stayview.reservation.reservation_data.title' | translate}}
  </div>
  <div class="navbar-end">
    <div class="navbar-item">
      <button id="multiBooking" routerLink="/multi-reservation/{{hotelID}}" [queryParams]="{user: currentUser }">
        Multi Booking
      </button>
      <button id="gb-btn" routerLink="/group-booking/{{hotelID}}" [hidden]="!allowToCreateGroupReservations" [queryParams]="{user: currentUser }">
        {{'stayview.reservation.reservation_data.groups' | translate}}
      </button>
      <button id="ooobutton" routerLink="/reservation/{{hotelID}}" [state]="{outOfOrder:true}" [hidden]="!allowToCreateOutofOrder" [queryParams]="{user: currentUser }">
        {{'stayview.reservation.reservation_data.out_of_order' | translate}}
      </button>
      <button id="mgbutton" routerLink="/reservation/{{hotelID}}" [state]="{managementBlock:true}" [hidden]="!allowToCreateComplimentary" [queryParams]="{user: currentUser }">
        {{'stayview.reservation.reservation_data.complimentary' | translate}}
      </button>
      <button id="newbutton" routerLink="/reservation/{{hotelID}}" [queryParams]="{user: currentUser }" [hidden]="!showCreateReservation" [ngStyle] ="!showCreateReservation && {'opacity': '0.6'}">
        {{'stayview.reservation.reservation_data.create_reservation' | translate}} 
      </button>
    </div>
  </div>
</nav>
<hr>
<div class=" mat-elevation-z8">
  <div class="form-row">
      <div class="form-group col-md-2 col-sm-2">
        <label for="fromDate">{{'stayview.reservation.reservation_data.from_date' | translate}}:</label>
        <input type="date" class="form-control form-control-sm" onkeydown="return false" id="fromDate" [(ngModel)]='FromDate' name="FromDate" >
      </div>
      <div class="form-group col-md-2 col-sm-2">
        <label for="toDate">{{'stayview.reservation.reservation_data.to_date' | translate}}:</label>
        <input type="date" class="form-control form-control-sm" onkeydown="return false" id="toDate" [(ngModel)]='ToDate' name="ToDate" >
      </div>
      <div class="form-group col-md-1 col-sm-1">
        <label for="filters">{{'stayview.reservation.reservation_data.filter_options' | translate}}:</label>
        <select class="form-control form-control-sm" id="filters" [(ngModel)]="filteron" name="filteron">
          <option *ngFor="let title of titles" [ngValue]="title.name">{{title.name}}</option>
        </select>
      </div>
      <div class="col-sm-1" id="submit" >
        <button type="button" class="a-button form-control-sm" (click)="getData1()">{{'stayview.reservation.submit' | translate}}</button>
      </div>
      <div class="form-group col-md-2 col-sm-2">
        <label for="searching">{{'stayview.reservation.reservation_data.search' | translate}}:</label>
        <input type="text" class="form-control form-control-sm" (keyup)="applyFilter($event)" id="searching">
      </div>
      <div class="form-check col-md-2 col-sm-2">
        <label for="cancelfilter" class='form-check-label'>
          <br>
        <input type="checkbox" class="form-check-input" [(ngModel)]="cancelled_bookings" id="cancelfilter" name="cancelfilter">
        {{'stayview.reservation.reservation_data.cancelled_bookings' | translate}}</label>
      </div>
      <div class="col-sm-1" id="excelfile" >
        <button type="button" class="a-button form-control-sm" (click)="download()">{{'stayview.reservation.reservation_data.download' | translate}}</button>
      </div>
      <div class="col-md-1 ml-auto">
        <div class="upload-btn-wrapper">
          <button  id="fileup" type="button" class="btn1 form-control form-control-sm">{{'stayview.reservation.reservation_data.upload' | translate}}</button>
          <input type="file" name="documents" ngModel accept=".xls" (change)="handleInput($event.target)"/>
        </div>
      </div>
  </div>
  <div class="example-container table-responsive">
    <table mat-table [dataSource]="dataSource" matSort >
      <!-- Booking ID Column -->
      <ng-container matColumnDef="Booking_ID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'stayview.reservation.reservation_data.booking_id' | translate}} </th>
        <td mat-cell class="left" *matCellDef="let element"><a data-toggle="modal" data-target="#myModal" style="color: #3c8dbc;cursor: pointer;" data-backdrop="false" (click)="bookingdetails(element.Booking_ID)"> {{element.Booking_ID}} </a></td>
      </ng-container>
  
      <!-- Customer_Name Column -->
      <ng-container matColumnDef="Customer_Name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'stayview.reservation.reservation_data.customer_name' | translate}} </th>
        <td mat-cell class="left" *matCellDef="let element"> {{element.Customer_Name}} </td>
      </ng-container>
  
      <!-- Booking_date Column -->
      <ng-container matColumnDef="Booking_Date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'stayview.reservation.reservation_data.booked_on' | translate}}</th>
        <td mat-cell class="left" *matCellDef="let element"> {{element.Booking_Date | date:'d, MMM, yyyy, h:mm a'}} </td>
      </ng-container>
  
      <!-- checkin_date Column -->
      <ng-container matColumnDef="Checkin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'stayview.reservation.reservation_data.checkin' | translate}}</th>
        <td mat-cell class="left" *matCellDef="let element"> {{element.Checkin | date:'MMM d, yy'}} </td>
      </ng-container>

      <!-- checkout_date Column -->
      <ng-container matColumnDef="Checkout">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'stayview.reservation.reservation_data.checkout' | translate}}</th>
        <td mat-cell class="left" *matCellDef="let element"> {{element.Checkout | date:'MMM d, yy'}} </td>
      </ng-container>
  
      <!-- Source Column -->
      <ng-container matColumnDef="Source">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'stayview.reservation.reservation_data.source' | translate}}</th>
        <td mat-cell class="left" *matCellDef="let element"> {{element.Source}} </td>
      </ng-container>

      <!-- Guests Column -->
      <ng-container matColumnDef="Guests">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'stayview.reservation.reservation_data.guests' | translate}} </th>
        <td mat-cell id="middle" *matCellDef="let element"> {{element.Guests}} </td>
      </ng-container>

      <!-- Rooms Column -->
      <ng-container matColumnDef="Rooms">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'stayview.reservation.reservation_data.rooms' | translate}} </th>
        <td mat-cell id="middle1" *matCellDef="let element"> {{element.Rooms}} </td>
      </ng-container>      

      <!-- Room Nights Column -->
      <ng-container matColumnDef="Room_Nights">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'stayview.reservation.reservation_data.nights' | translate}} </th>
        <td mat-cell id="middle2" *matCellDef="let element"> {{element.Room_Nights}} </td>
      </ng-container>

      <!-- Amount Column -->
      <ng-container matColumnDef="Amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'stayview.reservation.reservation_data.amount' | translate}} </th>
        <td mat-cell id="middle3" *matCellDef="let element"> {{element.Amount | currency:currencycode}} </td>
      </ng-container>

      <!-- Payment Mode Column -->
      <ng-container matColumnDef="Payment_Mode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'stayview.reservation.reservation_data.payment_mode' | translate}} </th>
        <td mat-cell class="left" *matCellDef="let element"> 
          <div class="d-flex justify-content-between align-items-center">
            <span>{{element.Payment_Mode}}</span>

            <svg *ngIf="element.card"
              (click)="getCardDetails(element)"
              role="button"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
              style="height: 10px; width: 10px; margin-right: 10px;"
              [matMenuTriggerFor]="ccDetails"
              >
              <path
                d="M64 32C28.7 32 0 60.7 0 96v32H576V96c0-35.3-28.7-64-64-64H64zM576 224H0V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V224zM112 352h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm112 16c0-8.8 7.2-16 16-16H368c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16z" />
            </svg>

            <mat-menu #ccDetails="matMenu">
              <div class="card_details">
                <div>Card No.: <span>{{element.creditCard?.fetched?.cardNumber}}</span></div>
                <div>Expiry: <span>{{element.creditCard?.fetched?.expiry}}</span></div>
                <div>CVV: <span>{{element.creditCard?.fetched?.cvv}}</span></div>
              </div>
            </mat-menu>

          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="Payment_Link">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Payment Link </th>
        <td mat-cell id="middle1" *matCellDef="let element">
          <div class="d-flex align-items-center">
            <button *ngIf="element.Payment_Mode === 'Postpaid' && element.state === 'new'"
              mat-raised-button class="bg-success text-white mr-1 btn_small" (click)="sendPaymentLink(element)">
              Send Link
            </button>
            <mat-icon class="p-links"
              style="cursor: pointer;"
              title="View Payment Links"
              (click)="showPaymentLinks(element.Booking_ID)">
              visibility
            </mat-icon>
          </div>
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="Roomstatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'stayview.reservation.reservation_data.status' | translate}} </th>
        <td mat-cell id="middle1" *matCellDef="let element"> {{element.Roomstatus}} </td>
      </ng-container>
      <!-- MealPlan Column -->
      <ng-container matColumnDef="MealPlan">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'stayview.reservation.reservation_data.mealplan' | translate}} </th>
        <td mat-cell class="left" *matCellDef="let element"> {{element.MealPlan}} </td>
      </ng-container>

      <!-- action Column -->
      <ng-container matColumnDef="take_action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'stayview.reservation.reservation_data.action' | translate}} </th>
        <td mat-cell class="tkaction" *matCellDef="let element">
          <div class="example-button-row" >
            <span title="Edit"><button mat-icon-button aria-label="Example icon button with a edit icon" [disabled]="element.Roomstatus.length==0 || element.Roomstatus[0]=='No Show' || element.Roomstatus.indexOf('Checked in')!=-1 || element.Roomstatus.indexOf('Checked out')!=-1 || !showEditReservation" class="buttonsize"  (click)="editROw(element.Booking_ID)" >
              <mat-icon >edit</mat-icon>
            </button></span>
            <span title="Mark NoShow"><button mat-icon-button aria-label="Example icon button with a work_off icon" [disabled]="(element.Roomstatus.length==0 || !showMarkNoShow || !element.EnableMarkNoShow)" class="buttonsize" (click)="markNoShow(element.Booking_ID)">
              <mat-icon>work_off</mat-icon>
            </button></span>
            <span title="Cancel Reservation"><button type = "button" class = "btn btn-default" mat-icon-button aria-label="Example icon button with a delete icon" [disabled]="element.Roomstatus.length==0 || element.Roomstatus[0]=='No Show' || element.Roomstatus.indexOf('Checked in')!=-1 || element.Roomstatus.indexOf('Checked out')!=-1 || !showDeleteReservation" (click)="cancelreservation(element.Booking_ID,element.Checkin,element.Checkout,element.Amount)">
              <mat-icon>delete</mat-icon>
            </button></span>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      [ngClass]="{'markcancelled' : row.state=='cancelled'}" [hidden]="(row.state=='cancelled' && !cancelled_bookings) || row.state == 'void'"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[20, 30, 40]" showFirstLastButtons></mat-paginator>
  
  </div>
</div>
