<div>
    <div class="aio_heading">
        <h2>Ageing Report</h2>

        <div class="d-flex justify-space-between align-items-center">
            <button mat-raised-button class="primary_btn" routerLink="/companies/{{hotelId}}">
              Back
            </button>
        </div>
    </div>

    <mat-divider></mat-divider>

    <div class="container-fluid common_content">
        
        <table class="table">
            <thead class="thead-dark">
                <tr>
                    <th>Company Name</th>
                    <th class="text-right">0 - 30 days</th>
                    <th class="text-right">31 - 60 days</th>
                    <th class="text-right">61 - 90 days</th>
                    <th class="text-right">91 - 120 days</th>
                    <th class="text-right">120 - 151 days</th>
                    <th class="text-right">151 over days</th>
                    <th class="text-right">Total</th>
                </tr>
            </thead>
        
            <tbody>
                <tr *ngFor="let item of ageingReportData; let i=index"
                    [ngClass]="{'dark-blue': ageingReportData?.length - 1 === i}">
                    <td>{{item.company}}</td>
                    <td *ngFor="let amount of item.data"
                        class="text-right">
                        {{amount | number: '1.2-2'}}
                    </td>
                    <td class="text-right">{{item.total_amount}}</td>
                </tr>
            </tbody>
        </table>

    </div>

</div>


