import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompaniesRoutingModule } from './companies-routing.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { CompaniesComponent } from './companies.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddEditFormComponent } from './add-edit-form/add-edit-form.component';
import { CityLedgerComponent } from './city-ledger/city-ledger.component';
import { MatBadgeModule } from '@angular/material/badge';
import { TranslateModule } from '@ngx-translate/core';
import { MarkPaidComponent } from './city-ledger/mark-paid/mark-paid.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AgeingLedgerComponent } from './ageing-ledger/ageing-ledger.component';


@NgModule({
  declarations: [CompaniesComponent, AddEditFormComponent, CityLedgerComponent, MarkPaidComponent, AgeingLedgerComponent],
  imports: [
    CommonModule, CompaniesRoutingModule, MatDividerModule, MatFormFieldModule, MatIconModule,
    MatInputModule, MatButtonModule, MatListModule, MatMenuModule, ReactiveFormsModule, FormsModule, MatBadgeModule, TranslateModule,
    MatDialogModule
  ]
})
export class CompaniesModule { }
