import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PaymentsService } from 'src/app/core/payments.service';
import { MatDialog } from '@angular/material/dialog';
import { BookingDetailsComponent } from '../../booking-details/booking-details.component';
import { ReservationService } from 'src/app/core/reservation.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { PaymentLinksComponent } from '../payment-links/payment-links.component';
import { BankDetailsComponent } from '../bank-details/bank-details.component';


@Component({
  selector: 'app-payment-settlement',
  templateUrl: './payment-settlement.component.html',
  styleUrls: ['./payment-settlement.component.scss']
})
export class PaymentSettlementComponent implements OnInit {
  hotelRoute: any;
  hotelID: any;
  showSettlementReport:boolean = false;
  showPaymentStatus:boolean = true;
  showSelectedDateSettlementReport = false;

  displayedColumns: string[] ;
  dataSource = new MatTableDataSource();
  currencycode: any;
  paymentLink: any = '';

  constructor(public location: Location, private route: ActivatedRoute, private datePipe: DatePipe, 
    private paymentServ: PaymentsService, public dialog: MatDialog, private resService: ReservationService,
    public matdialog: MatDialog,) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  ELEMENT_DATA: any[];
  date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  fromDate: any;
  toDate: any;
  bookingId: any = '';
  transactId: any = '';
  name: any = '';
  orderStatus = 'paid';
  


  ngOnInit(): void {
    this.hotelRoute = this.route.params.subscribe((params) => {
      this.hotelID = params.hotelId;
    });
    this.resService.getCurrencycode(this.hotelID).subscribe((currency) => (this.currencycode = currency));
    this.setDefaultDates();
  }

  fetchPaymentStatus(startDate, endDate){
    this.paymentServ.getPaymentStatus(this.hotelID, startDate, endDate, this.transactId, this.bookingId, this.name, this.orderStatus, this.paymentLink).subscribe(success=>{
      this.dataSource.data = success;
      this.displayedColumns = ["booking_id", "name", "product", "transaction_id", "transaction_date", "checkin", "checkout", "amount", "pg_charges", "tax", "net_amount", "confirmation_id", "payment_link", "settlement_date"];
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  bookingdetails(bookingId){
    this.dialog.open(BookingDetailsComponent, {data: {bookingId, hotelId: this.hotelID, currencyCode: this.currencycode}});
  }

  setDefaultDates(){
    var currentDate = new Date();
    const fromdatecopy = new Date(Number(currentDate));
    fromdatecopy.setDate(1);
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.fromDate = this.datePipe.transform(fromdatecopy, 'yyyy-MM-dd');
    if(this.showPaymentStatus){
      this.fetchPaymentStatus(this.fromDate, this.toDate);
    }else if (this.showSettlementReport){
      this.fetchSettlements(this.fromDate, this.toDate);
    }
  }

  fetchSettlements(startDate, endDate){
    this.paymentServ.getSettlements(this.hotelID, startDate+'T00:00:00', endDate+'T00:00:00').subscribe(success=>{
      this.dataSource.data = success;
      this.displayedColumns = ['date_of_settlement', 'no_of_transactions', 'amt_transferred'];
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  fetchSettlementDataOnSelectedDate(date){
    this.showSelectedDateSettlementReport = true;
    this.showSettlementReport = false;
    this.displayedColumns = [];
    this.paymentServ.getSettlementDataOnDate(this.hotelID, date).subscribe(success=>{
      this.dataSource.data = success;
      this.displayedColumns = ["booking_id", "name", "product", "transaction_id", "transaction_date", "checkin", "checkout", "amount", "pg_charges", "tax", "net_amount", "confirmation_id", "payment_link", "settlement_date"];
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  showFullSettlementReport(){
    this.showSelectedDateSettlementReport = false;
    this.showPaymentStatus = false;
    this.showSettlementReport = true;
    this.setDefaultDates();
    this.displayedColumns = [];
  }

  showPaymentStatusPage(){
    this.showPaymentStatus = true;
    this.showSettlementReport = false;
    this.showSelectedDateSettlementReport = false;
    this.setDefaultDates();
    this.displayedColumns = [];
  }

  sendPaymentLinksAuto(){
    const dialogBody = {
              title: 'Send Payment Links Automatically',
              body: 'All Postpaid bookings will be sent a Payment Link automatically on their email ID.'+ 
              'When the payment is made, the details will be recorded in Aiosell automatically.',
              confirmBtn: 'Ok',
              cancelBtn: 'Cancel',
              width: '500px',
              height: '200px',
            };
    
            const dialogRef = this.matdialog.open(DialogComponent, {
              data: dialogBody,
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result === 'true') {
                this.paymentServ.sendAutoPaymentLinkConfig(this.hotelID).subscribe();
              }
            })
  }

  // PaymentLinksComponent

  createPaymentLink(){
    var hotelId = this.hotelID
    this.matdialog.open(PaymentLinksComponent, {
          width: '650px',
          height: '140px',
          data: {
            hotelId
          },
        });
  }

  showBankDetails(){
    this.paymentServ.getBankDetails(this.hotelID).subscribe(data=>{
      const dialogRef = this.matdialog.open(BankDetailsComponent, {
        data: data,
        width: '420px',
        height: '200px',
        maxHeight: '80vh', // Ensures it doesn’t overflow
      });
    })
  }

}
