import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Globals } from 'src/app/core/globals.service';
import { LeadsService } from 'src/app/core/leads.service';
import { ReservationService } from 'src/app/core/reservation.service';
import { Statuses } from 'src/app/models/leads';
import { ILeadsInfo } from 'src/app/models/rooms';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';

@Component({
  selector: 'app-create-lead',
  templateUrl: './create-lead.component.html',
  styleUrls: ['./create-lead.component.scss']
})
export class CreateLeadComponent implements OnInit {
  pageTitle: string;
  leadsForm: FormGroup;
  hotelId: string;
  mode: 'new' | 'modify' = 'new';
  types = [
    'Rooms',
    'Banquet',
    'Restaurant'
  ];
  showRooms = false;
  leadId: string | undefined;
  leadData: any;
  submitting = false;
  leadConfig: any;
  statuses: any[];
  payload: any;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private leadsService: LeadsService,
    public reservationService: ReservationService,
    private globals: Globals,
    private router: Router,
    private dialog: MatDialog,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.statuses = Statuses.filter(e => e.value !== 'cancelled');
    
    const routeSnap = this.route.snapshot;
    this.hotelId = routeSnap.params?.hotelId;
    this.leadId = routeSnap.params?.leadId;
    
    if (this.leadId) {
      this.mode = 'modify';
      this.pageTitle = 'Edit Lead';
      this.getLead();
    } else {
      this.pageTitle = 'Create Lead';
      this.initForm();
    }
    this.getLeadConfig();
  }

  initForm(data?: any) {
    const pax = data?.pax?.length ? data.pax.reduce((a , b) => a + b, 0) : 1;
    
    this.leadsForm = this.fb.group({
      type: [data?.type, [Validators.required]],
      startDate: [data?.startDate, [Validators.required]],
      endDate: [data?.endDate],
      guestName: [data?.guestName, Validators.required],
      email: [data?.email],
      phone: [data?.phone],
      pax: [pax, Validators.required],
      expirationDate: [data?.expirationDate],
      advancePayment: [data?.advancePayment],
      totalAmount: [data?.totalAmount],
      createdBy: [data?.createdBy, Validators.required],
      status: [data?.status ? data.status : 'blocked', [Validators.required]],
      comments: [data?.comments],
      bookingIds: [null],
      reservations: [],
      city: [data?.city],
      country: [data?.country]
    });

    this.valueChangesSub();
    this.leadsForm.controls.type.setValue(data?.type ? data.type : this.types[0]);
  }

  getLead() {
    this.leadsService.getLeadById(this.hotelId, this.leadId)
    .subscribe(res => {
      if (res?.success) {
        this.leadData = res.data;
        this.payload = this.leadData?.payload;
        this.convertDates(this.leadData);
        this.initForm(this.leadData);
        this.showRoomsFun();
      }
    });
  }

  getLeadConfig() {
    this.leadsService.getLeadConfig(this.hotelId)
    .subscribe(res => {
      this.leadConfig = res;
      this.types = this.leadConfig?.conf.types?.length ? this.leadConfig?.conf?.types : this.types;
    });
  }

  submit() {
    if (this.leadsForm.invalid) {
      this.dialog.open(DialogComponent, {
        data: {title: 'Oops!', body: `Please enter required feilds`, confirmBtn: 'Ok'}
      });
      return;
    }
    const body = this.leadsForm.value;
    if (!body.endDate) {
      body.endDate = body.startDate; 
    }
    body.pax = body.pax?.length ? body.pax : [body.pax];
    
    this.submitting = true;

    if (this.leadId) {
      this.updateLead(body);
    }
    else {
      this.createLead(body);
    }
  }

  createLead(body) {
    this.leadsService.createLeads(this.hotelId, body)
    .pipe( finalize(() => this.submitting = false))
    .subscribe(res => {
      if (res?.success) {
        this.globals.snackBarFunc('Lead created !');
        this.router.navigate([`leads/${this.hotelId}`]);
      }
    });
  }

  updateLead(body) {
    this.leadsService.updateLead(this.hotelId, body, this.leadId)
    .pipe( finalize(() => this.submitting = false))
    .subscribe(res => {
      if (res?.success) {
        this.globals.snackBarFunc('Lead updated !');
        this.router.navigate([`leads/${this.hotelId}`]);
      }
    });
  }

  valueChangesSub() {
    this.leadsForm.controls.type.valueChanges.subscribe(() => {
      const addValidator = this.leadsForm.controls.type.value === 'Rooms' ? false : true;
      this.toggleValidatorsForRooms(addValidator);
      this.showRoomsFun();
    });
    this.leadsForm.controls.status.valueChanges.subscribe(() => {
      this.showRoomsFun();
    });
  }

  toggleValidatorsForRooms(validator: boolean) {
    const controls = ['startDate', 'guestName', 'totalAmount'];
    controls.forEach(c => {
      if (validator) {
        this.leadsForm.controls[c].setValidators(Validators.required);
      } else {
        this.leadsForm.controls[c].clearValidators();
      }
      this.leadsForm.controls[c].updateValueAndValidity();
    });
  }

  showRoomsFun() {
    const type = this.leadsForm.controls.type.value;
    this.showRooms = type === 'Rooms' ? true : false;
  }

  onRoomsBooked(leadInfo: ILeadsInfo) {
    this.globals.snackBarFunc('Lead created !');
    this.router.navigate([`leads/${this.hotelId}`]);
    return;
  }

  convertDates(data: any) {
    const keys = ['startDate', 'endDate', 'expirationDate'];
    for (const k of keys) {
      data[k] = this.datePipe.transform(data[k], 'y-MM-dd');
    }
  }

}
