<ng-container *ngIf="mode === 'editStay'">
    <div class="service_container">
        <div id="top-row" class="mb-1">
            <div id="invoice-buttons">
                <button *ngFor="let invoice of invoiceData; let i=index;"
                    mat-raised-button class="select-invoice"
                    (click)="changeInvoice(i)"
                    [ngClass]="{'active': i === currentInvoiceIndex}">
                    <ng-container *ngIf="invoice.status === 'Billed'; else unbilledBlock">
                        {{invoice.invoice_num}}
                    </ng-container>
                    <ng-template #unbilledBlock>
                        Invoice {{i + 1}}
                    </ng-template>
                </button>
            </div>
        </div>
        <table *ngIf="invoiceData" class="table table-striped">
            <thead class="thead-dark">
                <tr class="text-center">
                    <th>
                        <mat-icon (click)="checkBoxToggleAll()">select_all</mat-icon>
                    </th>
                    <th class="text-left">{{'stayview.service_detail.date' | translate}}</th>
                    <th class="text-left">{{'stayview.service_detail.category' | translate}}</th>
                    <th class="text-left">{{'stayview.service_detail.name' | translate}}</th>
                    <th>{{'stayview.service_detail.qty' | translate}}</th>
                    <th class="text-right">Gross Amt</th>
                    <th>Net Amt</th>
                    <th class="text-right">{{'stayview.service_detail.tax' | translate}}</th>
                    <th class="text-right">{{'stayview.service_detail.total_amount' | translate}}</th>
                    <th *ngIf="invoiceData && invoiceData[currentInvoiceIndex]?.pos_name === 'Rooms'">
                        {{'stayview.service_detail.action' | translate}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let service of servicesUsedData" class="text-center">
                    <td>
                        <mat-checkbox 
                            [(ngModel)]="service.checked" 
                            [value]="service.service_used_id"
                            [ngModelOptions]="{standalone: true}">    
                        </mat-checkbox>
                    </td>
                    <td class="text-left">{{service.service_date | date : 'MMM d'}}</td>
                    <td class="text-left">{{service.service_category}}</td>
                    <td class="text-left">
                        {{service.service_name}}
                        <mat-icon *ngIf="service.comments" class="info_btn" title="{{service.comments}}">info</mat-icon>
                    </td>
                    <td>{{service.quantity}}</td>
                    <td class="text-right">{{service.service_amount | currency: currency: 'symbol':'.2-2'}}</td>
                    <td>
                        {{service.net_amount | currency: currency: 'symbol': '.2-2'}}
                    </td>
                    <td class="text-right">{{service.tax.total | currency: currency: 'symbol': '.2-3'}}</td>
                    <td class="text-right">{{service.total_amount | currency: currency: 'symbol': '.2-2'}}</td>
                    <td class="actions" *ngIf="invoiceData && invoiceData[currentInvoiceIndex]?.pos_name === 'Rooms'">
                        <mat-icon (click)="deleteServiceUsed(service.service_used_id, service.total_amount, service.service_name, service)"
                        [hidden]="service.service_category === 'Rooms' || (invoiceData && invoiceData[currentInvoiceIndex]?.status === 'Billed')">
                            delete
                        </mat-icon>
                    </td>
                </tr>
                <tr>
                    <td colspan="4" class="text-right font-weight-bold">{{'stayview.service_detail.total' | translate}} :</td>
                    <td class="text-center font-weight-bold">{{quantitySum}}</td>
                    <td class="text-right font-weight-bold">{{amountSum | currency: currency : 'symbol': '.2-3'}}</td>
                    <td class="text-center font-weight-bold">
                        {{invoiceData[currentInvoiceIndex]?.net_amount_sum | currency: currency : 'symbol': '.2-2'}}
                    </td>
                    <td class="text-right font-weight-bold">{{taxSum | currency: currency : 'symbol': '.2-3'}}</td>
                    <td class="text-right font-weight-bold">{{totalAmountSum | currency: currency : 'symbol': '.2-2'}}</td>
                    <td class="text-right font-weight-bold">&nbsp;</td>
                </tr>
            </tbody>
        </table>

        <div class="balance_block">
            <div *ngIf="invoiceData && status === 'Checked in'">
                <button mat-raised-button (click)="changeMode('newEntry')" 
                    [disabled]="!(status==='Checked in')" class="confirm-button">
                    {{'stayview.service_detail.add_service' | translate}}
                </button>
            </div>
    
            <div *ngIf="balanceAmount !== null" id="balance">
                <h3>{{'stayview.service_detail.balance' | translate}} :</h3>
                <h3 class="text-danger ml-1">{{ balanceAmount | currency: currency : 'symbol': '.2-2'}}</h3>
            </div>
        </div>
        
        <div class="action-buttons">

            <div>
                <div *ngIf="status === 'Checked in'" 
                    class="d-flex flex-row align-items-end mr-auto gap-2 discount_block">
                    <div>
                        <label>{{'stayview.service_detail.discount' | translate}}</label>
                        <input type="number" class="form-control form-control-sm" min="1" placeholder="Enter discount" [(ngModel)]="discount">
                    </div>

                    <select name="discount-type" class="form-control form-control-sm" [(ngModel)]="discountType">
                        <option value="" disabled>Type</option>
                        <option value="%">{{'stayview.service_detail.percentage' | translate}}</option>
                        <option value="flat">{{'stayview.service_detail.flat' | translate}}</option>
                    </select>

                    <select class="form-control form-control-sm" [(ngModel)]="discountAppliedOn">
                        <option value="preTax">Pre Tax Amount</option>
                        <option value="postTax">Total Amount</option>
                    </select>

                    <button mat-raised-button 
                        type="button" 
                        class="active small-btn" 
                        (click)="applyDiscount()"
                        [ngClass]="{'disabled-btn' : !(servicesUsedData?.length && discountApplicable && discount && features?.live.discounts && discountType)}">
                        {{'stayview.service_detail.apply' | translate}}
                    </button>

                    <button class="ml-2"
                        [ngClass]="{'disabled-btn': features?.live?.hasOwnProperty('undoDiscounts') && !features?.live?.undoDiscounts}"
                        mat-raised-button 
                        (click)="getDiscountLog()">
                        Undo
                    </button>
                </div>
            </div>
        
            <div class="d-flex flex-column mt-2" style="width: 450px;">
                
                <table>
                    <tr *ngIf="invoiceData && status === 'Checked in'">
                        <td class="transfer-label">
                            <label class="font-weight-bold" for="Transfer Invoice">Transfer Invoice to Room Folio</label>
                        </td>
                        <td>
                            <ng-select (change)="selectCheckedInGuest($event, 'transferInvoiceToRoom')" class="form-control form-control-sm" >
        
                                <ng-option *ngFor="let guest of checkedInGuests; let i=index" [value]="guest">
                                    <div [ngStyle]="i%2 === 0 && {'background': '#E7E9EB'}">
                                        {{guest.room_num}} - {{guest.guest_name}}
                                    </div>
                                </ng-option>
                            </ng-select>
                        </td>
                    </tr>

                    <tr *ngIf="invoiceData && status === 'Checked in' && invoiceData[currentInvoiceIndex]?.pos_name === 'Rooms'">
                        <td class="transfer-label">
                            <label class="font-weight-bold" for="Transfer Item">Transfer selected item to Room Invoice</label>
                        </td>
                        <td>
                            <ng-select (change)="selectCheckedInGuest($event, 'transferToInvoice')" class="form-control form-control-sm" >
        
                                <ng-option [value]="{'newInvoice': true}">New Invoice</ng-option>
                                <ng-container *ngFor="let invoice of invoiceData; let i=index;">
                                    <ng-option *ngIf="i != currentInvoiceIndex && invoice.status === 'Unbilled'" [value]="{'invoiceTransferIndex': i}">
                                        Invoice {{i+1}}
                                    </ng-option>
                                </ng-container>
                
                                <ng-option *ngFor="let guest of checkedInGuests; let i=index" [value]="guest">
                                <div [ngStyle]="i%2 === 0 && {'background': '#E7E9EB'}">
                                    {{guest.room_num}} - {{guest.guest_name}}
                                </div>
                                </ng-option>
                            </ng-select>
                        </td>
                    </tr>
                </table>
       
            </div>

        </div>

        <div *ngIf="invoiceData?.length && status !== 'Checked out'">
            <label class="text-secondary">
                Do not generate Invoice No.
                <input type="checkbox" [(ngModel)]="invoiceData[currentInvoiceIndex].do_not_generate_invoice" 
                    (change)="doNotGenInvoiceNum($event.target.checked)">
            </label>
        </div>

    </div>

</ng-container>

<ng-container *ngIf="mode === 'newEntry'">
    <div class="add-service" [ngClass]="{'pointer_none': loading}">
        <div class="row">
            <h1>{{'stayview.service_detail.add_service' | translate}}</h1>
            <hr>
        </div>
        <div class="add-service-form">
            <form [formGroup]="addServiceUsedForm" (ngSubmit)="submit(addServiceUsedForm)">
                <div class="row" style="margin-top: 0;">
                    <div class="col-sm-3">
                        <label for="Category">{{'stayview.service_detail.service_category' | translate}}</label>
                        <select class="form-control form-control-sm" id="category" name="servicecategory"
                            (change)="filterServices($event.target.value); amount=0;"
                            formControlName="category">
                            <option value="" disabled selected hidden>{{'stayview.service_detail.select_category' | translate}}</option>
                            <option 
                                *ngFor="let category of serviceCategories">
                                {{category.category}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-3">
                        <label for="service">{{'stayview.service_detail.service' | translate}}</label>
                        <select class="form-control form-control-sm" id="service" name="service" value="select"
                            (change)="getAmount($event.target.value); quantity=1;" formControlName="service_name">
                            <option value="" selected hidden>{{'stayview.service_detail.select_service' | translate}}</option>
                            <option 
                                *ngFor="let name of serviceNames"
                                [value]="name">{{name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-6">
                        <label>Comments</label>
                        <input type="text" class="form-control form-control-sm" id="description" [(ngModel)]=description
                        formControlName="comments">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-3">
                        <label for="Category">{{'stayview.service_detail.amount' | translate}}</label>
                        <input type="number" class="form-control form-control-sm" id="amount" [(ngModel)]=newAmount
                        formControlName="service_amount" [readonly]="taxInclusive === 'Yes' || isEditable === 'No'"
                        (input)="amountChange(newAmount)">
                    </div>
                    <div class="col-sm-3">
                        <label for="Category">{{'stayview.service_detail.tax' | translate}}</label>
                        <input type="number" class="form-control form-control-sm" [(ngModel)]=newTaxAmount
                        formControlName="tax" readonly>
                    </div>
                    <div class="col-sm-3">
                        <label for="Category">{{'stayview.service_detail.total_amount' | translate}}</label>
                        <input type="number" class="form-control form-control-sm" [(ngModel)]=totalAmount
                        formControlName="total_amount" [readonly]="taxInclusive === 'No' || isEditable === 'No'"
                        (input)="calculateAmount(totalAmount)">
                    </div>
                    <div class="col-sm-1" style="padding-left: 0;">
                        <label for="quantity">{{'stayview.service_detail.qty' | translate}}</label>
                        <input type="number" min="1" class="form-control form-control-sm" id="quantity" [(ngModel)]=quantity
                        formControlName="quantity">
                    </div>

                    <div class="col-sm-1">
                        <label for="" style="visibility: hidden;">Add</label>
                       <button type="button" mat-raised-button (click)="bulkAdd()">Add</button>
                    </div>
                </div>
                <div class="row" *ngIf="!(addServiceUsedForm.controls['quantity'].valid)">
                    <small class="text-danger">*{{'stayview.service_detail.quantity_cannot_be_zero' | translate}}*</small>
                </div>

                <table class="table table-sm" style="margin-top: 50px;">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Tax</th>
                            <th>Total Amt</th>
                            <th>Qty</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let service of bulkAddServices; let i=index">
                            <td>{{service.service_name}}</td>
                            <td>{{service.service_amount}}</td>
                            <td>{{service.tax?.total}}</td>
                            <td>{{service.total_amount}}</td>
                            <td>{{service.quantity}}</td>
                            <td>
                                <mat-icon class="text-danger" (click)="removeService(i)">
                                    delete
                                </mat-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="row action-buttons mt-4" style="margin-bottom: 0;">
                    <button *ngIf="!newEntryplusView"
                        mat-raised-button mat-dialog-close>
                        {{'stayview.service_detail.cancel_btn' | translate}}
                    </button>
                    <button *ngIf="newEntryplusView"
                        (click)="changeMode('editStay')"
                        mat-raised-button>{{'stayview.service_detail.cancel_btn' | translate}}
                    </button>
                    <button type="submit" mat-raised-button mat-dialog-close
                        [disabled]="!bulkAddServices.length"
                        class="confirm-button">{{'stayview.service_detail.submit_btn' | translate}}
                    </button>
                </div>
            </form>
        </div>
    </div>
</ng-container>