import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core/user.service';
import { Globals } from '../../core/globals.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { EditStayComponent } from '../stayview/edit/edit-stay/edit-stay.component';
import { CommunicationService } from 'src/app/core/communication.service';
import { QuickReservationComponent } from '../stayview2/quick-reservation/quick-reservation.component';

interface Guest {
  bookingId: string;
  roomAllocationId: string;
  checkin: string;
  checkout: string;
}

@Component({
  selector: 'app-roomview',
  templateUrl: './roomview.component.html',
  styleUrls: ['./roomview.component.scss']
})

export class RoomviewComponent implements OnInit {
  currentUser: string;
  currencyCode: string;
  currentDate: any;
  hotelId: string;
  date: any;
  roomViewData: any;
  unassignedGuest: Guest;
  summary: any;
  dragGuest: any;

  selectRoom = false;
  mouseOverRoom = {value: false, roomNum: null};
  features: any = {};
  iconPath = '../../../../assets/img/source_icons/'
  sourceIconMapping = {
    'booking.com': 'bookingdotcom.ico',
    'Goibibo': 'goibibo.ico',
    'MakeMyTrip': 'mmt.ico',
    'Expedia': 'expedia.ico',
    'Yatra': 'yatra.ico',
    'Airbnb': 'airbbnb.ico',
    'Aiosell BE': 'aiosell.ico',
    'agoda': 'agoda.ico',
    'Hostelworld': 'hostelworld.ico'
  };
  balances = {};
  checkedInGuests = [];
  assignedGuests = [];
  floorView = false;

  constructor(public globals: Globals, public userService: UserService, public datePipe: DatePipe,
              private route: ActivatedRoute, private router: Router, private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer, public dialog: MatDialog,
              private commService: CommunicationService) {
    this.matIconRegistry.addSvgIcon('broom', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/Broom.svg'));
    this.matIconRegistry.addSvgIcon('clean', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/clean.svg'));
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.hotelId = params.hotelId;
      this.globals.changeHotelID(this.hotelId);
    });
    this.getCurrentDate();
    this.currentUser = localStorage.getItem('user');
    this.globals.getCurrencyCode(this.hotelId).subscribe(data => {
      this.currencyCode = data;
    });

    this.userService.userFeatures$.subscribe(data => {
      this.features = data;
    });
    this.commService.stayViewReload.subscribe(e => this.emitChange());
    this.getProductConfig();
  }

  getProductConfig() {
    this.userService.getProductConfig(this.hotelId)
    .subscribe(data => {
      if (data) {
        this.globals.setConfig(data.conf);
      }
    });
  }

  toggleMouseOver(bool, roomNum = null) {
    this.mouseOverRoom.value =  bool;
    this.mouseOverRoom.roomNum =  roomNum;
  }

  changeDay(move) {
    this.date = new Date(this.date);
    if (move === 'next') {
      this.date.setDate(this.date.getDate() + 1);
    } else if (move === 'prev') {
      this.date.setDate(this.date.getDate() - 1);
    }
    this.date = this.datePipe.transform((new Date(this.date)), 'y-MM-d');
    this.getRoomView(this.date);
  }

  getCurrentDate() {
    this.userService.getCurrentDate(this.hotelId).subscribe(res => {
      this.currentDate = res?.currentDate;
      this.date = this.currentDate;
      this.getRoomView(this.date);
    })
  }

  datePicked(datePickerValue): void {
    this.date = new Date(datePickerValue);
    this.date = this.datePipe.transform((new Date(this.date)), 'y-MM-d');
    this.getRoomView(this.date);
  }

  getRoomView(date) {
    this.checkedInGuests = [];
    this.assignedGuests = [];
    this.userService.getRoomView(this.hotelId, date, this.floorView).subscribe(
      data => {
        if (data?.success) {
          this.roomViewData = data.data?.roomsData;
          this.summary = data.data?.totalSummary;

          this.roomViewData.forEach(rt => {
            rt.rooms.forEach(room => {
              room.allocations?.forEach(alloc => {
                if (alloc.status === 'Checked in') {
                  this.checkedInGuests.push(alloc.roomAllocationId);
                } else if (alloc.status === 'Assigned') {
                  this.assignedGuests.push(alloc.roomAllocationId)
                }
              })
            })
          });

          this.getBalances();
        }
      }
    );
  }

  toggleFloorView() {
    this.floorView = !this.floorView;
    this.getRoomView(this.date);
  }

  emitChange() {
    this.getRoomView(this.date);
  }

  goToStayView() {
    this.router.navigate([`/stay-view/${this.hotelId}`]);
  }

  cleanDirtyToggle(roomNumId, status, roomNum) {
    const dialogBody =
      {title: 'HOUSEKEEPING',
       body: `Change room status to ${status.toUpperCase()}?`,
       confirmBtn: 'Change',
       cancelBtn: 'Cancel'
      };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
          const user = localStorage.getItem('user');
          const body = {roomNumID: roomNumId, status, user, roomNum};
          this.userService.changeRoomDirty(body, this.hotelId).subscribe(
            data => {
              this.getRoomView(this.date);
            }, err => {
            });
        }
    });
  }

  selectGuest(unassignedGuest: Guest){
    this.selectRoom = true;
    this.unassignedGuest = unassignedGuest;
  }

  assignRoom(roomId, roomNum){
    const dialogBody =
      {title: 'ROOM ASSIGNMENT',
        body: `Assign to room ${roomNum} ?`,
        confirmBtn: 'Assign',
        cancelBtn: 'Cancel'
      };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    const user = localStorage.getItem('user');
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){
        const body = {
          roomAllocationId: this.unassignedGuest.roomAllocationId, bookingId: this.unassignedGuest.bookingId,
          roomNumId: roomId, user, roomNum, check_in: this.unassignedGuest.checkin,
          check_out: this.unassignedGuest.checkout
        };
        this.userService.allocateRoom(body, this.hotelId).subscribe(
          data => {
            if (data && 'error' in data) {
              this.errorMsg({message: data.error});
              return;
            }
            this.getRoomView(this.date);
          },
          err => {
            this.errorMsg({message: 'Something went wrong. Please refresh your browser and try again'});
          });
        }
    });
    this.selectRoom = false;
   }

  goToEdit(allocId, status, bookingId, source){
    const user = localStorage.getItem('user');
    this.dialog.open(EditStayComponent, {data : {allocId, hotelId: this.hotelId, status, bookingId, source, queryParams: {user}}});
  }

  dragStart(prevRoomNum, prevRoomNumId, roomAlloc) {
    if (this.dragGuest) {
      return;
    }
    this.dragGuest = {
      bookingId: roomAlloc.bookingId,
      roomAllocationId: roomAlloc.roomAllocationId,
      checkIn: roomAlloc.checkIn,
      checkOut: roomAlloc.checkOut,
      prevRoomNum, prevRoomNumId
    };
  }

  dragOver(event, bool, roomNum){
    event.preventDefault();
    this.mouseOverRoom.value =  bool;
    this.mouseOverRoom.roomNum =  roomNum;
    this.selectRoom = true;
  }

  drop(newRoomNum, newRoomNumId) {
    this.selectRoom = false;
    if (this.dragGuest.prevRoomNumId === newRoomNumId) {
      return;
    }
    this.dragGuest.newRoomNum = newRoomNum;
    this.dragGuest.newRoomNumId = newRoomNumId;
    this.dragGuest.user = localStorage.getItem('user');
    const body = this.dragGuest;
    const dialogBody =
      {title: 'CHANGE ROOM',
       body: `Are you sure you want to change room to: ${newRoomNum}?`,
       confirmBtn: 'Change',
       cancelBtn: 'Cancel'
      };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        this.userService.dragDrop(body, this.hotelId).subscribe(
          data => {
            if (data && 'error' in data) {
              this.errorMsg({message: data.error});
              return;
            }
            this.getRoomView(this.date);
          }
        );
      }
    });
    this.dragGuest = undefined;
  }

  dragEnd() {
    this.selectRoom = false;
  }
  
  allocationMenuOpened(guest) {
    this.commService.allocationMenuOpened(guest);
  }

  getBalances() {
    this.userService.getBalances(this.checkedInGuests, this.assignedGuests, this.hotelId).subscribe(res => {
      if (res?.success) {
        this.balances = res.data;
      }
    })
  }

  roomBoxClick(room, roomTypeId, roomTypeName) {
    if (this.selectRoom) {
      this.assignRoom(room.roomNumId, room.roomNum)
    } else if (!room.allocations?.length) {
      this.openQuickReservation({...room, roomTypeId, roomTypeName});
    }
  }

  openQuickReservation(room) {
    const checkin = this.datePipe.transform((new Date(this.date)), 'y-MM-dd');
    let checkout: any = new Date(this.date);
    checkout.setDate(checkout.getDate() + 1);
    checkout = this.datePipe.transform(checkout, 'y-MM-dd');

    const reservation = {checkin, checkout, room }
    this.dialog.open(QuickReservationComponent, {
      data: {
        reservation,
        hotelId: this.hotelId,
        features: this.features?.live
      },
      width: '800px'
    })
  }

  errorMsg(msg){
    const errorDialog =
      {title: 'ERROR !',
      body: msg.message,
      cancelBtn: 'Dismiss'
      };
    this.dialog.open(DialogComponent, {data: errorDialog});
   }
}
