<div class="dialog-box">
    <div class="aio_heading">
        <h2>Bank Details</h2>
    </div>

    <div class="container-fluid">
        <table class="bank-details-table">
            <tr>
              <td>Bank Name</td>
              <td>: {{data.bank_name}}</td>
            </tr>
            <tr>
              <td>Account Name</td>
              <td>: {{data.account_name}}</td>
            </tr>
            <tr>
              <td>Account Number</td>
              <td>: {{data.account_num}}</td>
            </tr>
            <tr>
              <td>IFSC Code</td>
              <td>: {{data.ifsc_code}}</td>
            </tr>
        </table>
        <div class="action-btns">
            <button mat-raised-button class="close_btn" (click)="closeDialog()">
                Close
            </button>
        </div>
    </div>
</div>